<template>
    <div class="page-container">
        <audio
          ref="game3"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="../../assets/game3.mp3">
        </audio>
        <div class="cover" v-show="showCover">
            <img class="start"  src="../../assets/imgs/game3-start.png"  @click="hideCover" alt="">
        </div>
        <div class="main-mask"  v-show="!showCover" v-if="startRemain > 0">
            <div class="mask-remain">
                {{startRemain}}
            </div>
        </div>
        <div class="main-container" v-show="!showCover">
            <img class="title" src="../../assets/imgs/g3-title.png" alt="">
            <div class="point">得分：<span>{{point}}</span></div>
            <div class="progress">
                <div class="circle-container">
                    <van-circle
                            v-model="currentRate"
                            :rate="rate"
                            layer-color="#d4d6d8"
                            :speed="100 / 60"
                            :stroke-width="80"
                            :color="gradientColor"
                            :text="remain + 's'"
                            :size="pxToVw(110)"
                            :clockwise="false"
                    />
                </div>
            </div>
            <div class="game-container">
                <div class="game-box">
                    <div class="game-box-wrapper" v-if="showList">
                        <div class="bonus" :class="showBonus ? 'bonus-on' : ''">{{calc > 0 ? '+': ''}}{{calc}}</div>
                        <div class="game-item" :class="['level'+level, bombRemain <=3 ? 'game-item-bomb': '']" v-for="item in Math.pow(2 + level, 2)" :key="item">
                            <img src="../../assets/imgs/g3-bomb.png" alt="">
                            <div class="count-down">
                                <span v-if="item == bombIdx" @click="clickBomb">0{{bombRemain}}</span>
                                <span v-else>{{bombOther}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {pxToVw} from '@/utils/index.js'
    export default {
        name: "index",
        data() {
            return {
                startRemain: 3,
                startTimer: null,
                showCover: true,
                remainTimer: null,
                rate: 100,
                currentRate: 100,
                remain: 60,
                point: 0,
                calc: 0,
                showBonus: false,
                gradientColor: {'0%': '#3fecff', '100%': '#561fe0',},
                level: 1,
                bombIdx: 0,
                bombRemain: 5,
                bombOther: 52,
                bombTimer: null,
                showList: false
            }
        },
        watch: {
            bombRemain() {
                this.bombOther = Math.ceil(55 - Math.random() * 45)
            }
        },
        methods: {
            pxToVw,
            hideCover() {
                this.showCover = false;
                this.handleStartRemain();
                setTimeout(() => {
                    this.$refs.game3.play()
                }, 100)
            },
            handleStartRemain() {
                if (this.startTimer) {
                    clearInterval(this.startTimer)
                    this.startTimer = null;
                }
                this.startTimer = setInterval(() => {
                    if (this.startRemain <= 1 ) {
                        this.startRemain = this.startRemain  - 1;
                        clearInterval(this.startTimer)
                        this.startTimer = null;
                        this.startGame();
                    } else {
                        this.startRemain = this.startRemain  - 1;
                    }
                }, 1000)
            },
            finishGame() {
                this.$router.push({
                    name: 'game3Res',
                    query: {point: this.point}
                })
            },
            startGame() {
                this.genBombIdx();
                this.genBombTimer();
                if (this.remainTimer) {
                    clearInterval(this.remainTimer)
                    this.remainTimer = null;
                }
                setTimeout(() => {
                    this.rate = 0;
                }, 1000)
                this.remainTimer = setInterval(() => {
                    if (this.remain <=0 ) {
                        clearInterval(this.remainTimer)
                        this.remainTimer = null;
                        this.finishGame();
                    } else {
                        this.remain = this.remain  - 1;
                    }
                }, 1000)
            },
            genBombIdx() {
                this.showList = false
                let count = Math.pow(2 + this.level, 2);
                this.bombIdx = Math.floor(Math.random()*count);
                setTimeout(() => {
                    this.showList = true
                }, 0)
            },
            genBombTimer() {
                this.bombTimer = setInterval(() => {
                    if (this.bombRemain <=0 ) {
                        clearInterval(this.bombTimer);
                        this.bombTimer = null;
                        this.finishGame();
                    } else {
                        this.bombRemain = this.bombRemain - 1;
                    }
                }, 1000)
            },
            clickBomb() {
                this.bombRemain = 5;
                clearInterval(this.bombTimer);
                this.bombTimer = null;

                this.point = this.point + 1
                this.showBonus = true;
                this.calc = 1
                setTimeout(() => {
                    this.showBonus = false
                    this.calc= 0;
                }, 1200)

                if (this.level >= 4) {
                    this.level = 4
                } else {
                    this.level = this.level + 1
                }
                this.genBombIdx();
                this.genBombTimer();
            }
        },
        created() {

        },
        mounted() {
            this.$refs.game3.play()
        },
        beforeDestroy() {
            clearInterval(this.remainTimer)
            this.remainTimer = null;
            clearInterval(this.timer);
            this.timer = null;
            clearInterval(this.bombTimer);
            this.bombTimer = null;
            clearInterval(this.startTimer);
            this.startTimer = null;
            this.$refs.game3.pause()
        },
    }
</script>

<style scoped lang="scss">
    .page-container{
        width: 100vw;
        min-height: 100vh;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            border: 1px solid #ccc;
        }
        .cover{
            width: 100vw;
            min-height: 100vh;
            position: relative;
            background-image: url("../../assets/imgs/game3.jpg");
            background-size: cover;
            background-position: center;
        }
        .start{
            @include horizontal();
            bottom: 100px;
            width: 306px;
            height: 115px;
        }
        .bg{
            width: 100%;
        }
        .main-container{
            width: 100vw;
            height: 100vh;
            position: relative;
            background-image: url("../../assets/imgs/g3-bg.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            .bonus{
                width: 100px;
                text-align: center;
                font-size: 60px;
                font-weight: bold;
                color: #fff;
                font-family: DIN;
                @include horizontal();
                z-index: 15;
                top: -50px;
                text-shadow: 0 0 10px rgba(0,0,0,0.3);
                opacity: 0;
            }
            .bonus-on{
                animation: bonus 1.2s ease forwards;
            }
            @keyframes bonus {
                0%{
                    transform: translateY(0px);
                    opacity: 1;
                }
                100%{
                    transform: translateY(-100px);
                    opacity: 0;
                }
            }
            .title{
                width: 750px;
                height: 78px;
                position: absolute;
                left: 0;
                top: 257px;
                z-index: 99;
            }
            .point{
                position: absolute;
                width: 200px;
                left: 60px;
                top: 15px;
                z-index: 10;
                font-size: 30px;
                color: #fff;
                span{
                    font-size: 36px;
                    font-weight: bold;
                    font-family: DIN;
                }
            }
            .progress{
                @include horizontal();
                top: 110px;
                width: 127px;
                height: 127px;
                z-index: 10;
                background: #fff;
                border-radius: 50%;
                .circle-bg{
                    width: 106px;
                    height: 106px;
                    @include center();
                    border: 4px solid #d4d6d8;
                    border-radius: 50%;
                    box-sizing: border-box;
                    z-index: 5;
                }
                .circle-container{
                    width: 110px;
                    height: 110px;
                    @include center();
                    z-index: 10;
                    ::v-deep(.van-circle__text){
                        color: #4377ff;
                        font-size: 36px;
                        font-family: DIN;
                        font-weight: bold;
                    }
                }
            }
            .game-container{
                width: 664px;
                height: 618px;
                background-image: url("../../assets/imgs/g3-box.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                @include center();
                .game-box-wrapper{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
                .game-box{
                    width: 520px;
                    height: 520px;
                    position: absolute;
                    top: 50px;
                    left: 70px;

                    .game-item{
                        position: relative;
                        overflow: hidden;
                        img{
                            @include fit(contain);
                            width: 90%;
                            height: 90%;
                            margin-top: 5%;
                            margin-left: 5%;
                        }
                        .count-down{
                            width: 50%;
                            height: 28%;
                            position: absolute;
                            left: 25%;
                            bottom: 20%;
                            z-index: 5;
                            /*display: flex;*/
                            /*justify-content: center;*/
                            /*align-items: center;*/
                            text-align: center;
                            span{
                                font-family: DIGITAL;
                                font-size: 170%;
                                color: #000;
                                display: inline-block;
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    .game-item-bomb{
                        animation: bomb 1s linear infinite;
                    }
                    @keyframes bomb {
                        0%{
                            transform: scale(1);
                        }
                        15% {
                            transform: scale(1.08);
                        }
                        30% {
                            transform: scale(1);
                        }
                        100%{
                            transform: scale(1);
                        }
                    }
                    .level1{
                        width: calc(520px / 3);
                        height: calc(520px / 3);
                        .count-down{
                            span{
                                font-size: 170%;
                            }
                        }
                    }
                    .level2{
                        width: calc(520px / 4);
                        height: calc(520px / 4);
                        .count-down{
                            span{
                                font-size: 150%;
                            }
                        }
                    }
                    .level3{
                        width: calc(520px / 5);
                        height: calc(520px / 5);
                        .count-down{
                            span{
                                font-size: 130%;
                            }
                        }
                    }
                    .level4{
                        width: calc(520px / 6);
                        height: calc(520px / 6);
                        .count-down{
                            span{
                                font-size: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
