<template>
  <div class="page-container" :class="loaded ? 'loaded':''">
    <audio
      ref="home"
      loop="loop"
      preload="auto"
      controls
    >
      <source src="@/assets/home.mp3">
    </audio>
    <div class="cover" :class="showCover ? 'cover-show': ''" @click="hideCover">
      <img class="cover-img" src="@/assets/imgs/cover.jpg" alt="" >
      <img class="cover-title" src="@/assets/imgs/cover-title-main.png" alt="">
    </div>
    <div class="cover-tip" :class="showTip ? 'cover-show': ''" >
      <img class="cover-img" src="@/assets/imgs/cover-tip.jpg" alt="" >
      <img @click="hideTip" class="cover-start" src="@/assets/imgs/cover-start.png" alt="" >
      <img class="cover-text" src="@/assets/imgs/cover-text.png" alt="" >
    </div>
    <div class="main-container">
      <div class="main-wrapper">
        <img class="page-title" src="@/assets/imgs/cover-title.png" alt="">
        <div v-show="!showForm" class="swiper-content">
          <div class="swiper" id="user-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, idx) in list" :class="[curUrl == item.url ? 'slide-on': '', curUrl ? 'pointer-none': '']" :key="idx">
                <div class="top">
                  <img :class="'top' + item.type" :src="item.top" alt="" >
                </div>
                <div class="bottom">
                  <div class="bottom-body">
                    <img class="title" :src="item.title" alt="">
                    <div class="n">
                      <img :src="item.number" alt="">
                    </div>
                    <div class="des">{{item.des}}</div>
                  </div>
                  <div class="btn">
                    <img class="btn-img" src="../../assets/imgs/home-btn.png">
                    <img class="btn-arrow"  v-if="!checkComplete(item)" src="../../assets/imgs/home-arrow.png">
                    <img class="btn-finish"  v-if="checkComplete(item)" src="../../assets/imgs/finish.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="showForm" class="form-container">
          <div class="form-body">
            <div class="item">
              <div class="label">昵&nbsp;&nbsp;&nbsp;&nbsp;称</div>
              <div class="input">
                <input v-model="form.name" type="text">
              </div>
            </div>
            <div class="item">
              <div class="label">手&nbsp;&nbsp;&nbsp;&nbsp;机</div>
              <div class="input">
                <input v-model="form.phone" type="text">
              </div>
            </div>
            <div class="item">
              <div class="label">邮&nbsp;&nbsp;&nbsp;&nbsp;箱</div>
              <div class="input">
                <input v-model="form.email" type="text">
              </div>
            </div>
            <div class="item submit-tip">请填入您的相关信息  便于后期通过邮箱通知您领取礼品</div>
          </div>
          <div class="copyright">
            <div class="left"> <van-checkbox v-model="checked" shape="square"></van-checkbox></div>
            <div class="right">同意将以上信息授权小程序使用，并同意小程序通过以上信息向您发送邮件。
              <span @click="show = true">《用户信息授权协议》</span></div>
          </div>
          <div class="submit">
            <img :class="checked ? '': 'unchecked'" @click="handleSubmit" src="@/assets/imgs/submit.png" alt="">
          </div>
        </div>
        <van-dialog v-model="show" >
         <div class="copy-modal">
          <div>本小程序非常重视用户隐私政策并严格遵守相关的法律规定。请您仔细阅读《隐私政策》后再继续使用。如果您继续使用我们的服务，表示您已经充分阅读和理解我们协议的全部内容。</div><br>
           <div>本小程序尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更优质的服务，我们会按照本隐私权政策的规定使用您的个人信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，我们不会将这些信息对外披露或向第三方提供。我们会不时更新本隐私权政策。 您在同意服务使用协议之时，即视为您已经同意本隐私权政策全部内容。
           </div><br>
           <div>1. 适用范围<br></div>
          <div>(a) 在您注册本应用小程序帐号时，您根据小程序要求提供的个人信息；<br></div>
          <div>(b) 在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；<br></div>
          <div>(c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。<br></div>
          <div> (d)本应用严禁用户发布不良信息，如裸露、色情和亵渎内容，发布的内容我们会进行审核，一经发现不良信息，会禁用该用户的所有权限，予以封号处理。<br></div>
           <br>
          <div>2. 信息使用<br></div>
          <div>(a)本应用小程序不会向任何无关第三方提供、出售、出租、分享或交易您的个人登录信息。如果我们存储发生维修或升级，我们会事先发出推送消息来通知您，请您提前允许本应用消息通知。<br></div>
          <div>(b) 本应用小程序亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用小程序平台用户如从事上述活动，一经发现，本应用小程序有权立即终止与该用户的服务协议。<br></div>
          <div>(c) 为服务用户的目的，本应用小程序可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用小程序合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）<br></div>
           <br>
          <div>3. 信息披露<br></div>
          <div>在如下情况下，本应用小程序将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：<br></div>
          <div>(a) 未经您事先同意，我们不会向第三方披露；<br></div>
          <div>(b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；<br></div>
          <div>(c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；<br></div>
          <div>(d) 如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；<br></div>
          <div>(e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；<br></div>
           <br>
          <div>4. 信息存储和交换<br></div>
          <div>本应用小程序收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用小程序收集信息和资料所在地的境外并在境外被访问、存储和展示。<br></div>
           <br>
          <div>5. Cookie的使用<br></div>
          <div>(a) 在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies,以便您能登录或使用依赖于cookies的本应用小程序平台服务或功能。本应用小程序使用cookies可为您提供更加周到的个性化服务，包括推广服务。<br></div>
          <div>(b) 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用小程序网络服务或功能。<br></div>
          <div>(c) 通过本应用小程序所设cookies所取得的有关信息，将适用本政策。<br></div>
           <br>
          <div>6.本隐私政策的更改<br></div>
          <div>(a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。<br></div>
          <div>(b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。<br></div>
           <br>
          <div>感谢您花时间了解我们的隐私政策！我们将尽全力保护您的个人信息和合法权益，再次感谢您的信任！<br></div>
         </div>
        </van-dialog>
      </div>
    </div>
    </div>
</template>

<script>
  import { mapActions, mapState} from 'vuex';
  import * as api from '@/api';
  import { Toast } from 'vant'
  import Swiper from 'swiper'
  import 'swiper/swiper.min.css';
  export default {
    name: "index",
    components: {},
    data() {
      return {
        game: 0,
        show: false,
        curUrl: '',
        loaded: false,
        showCover: true,
        showTip: true,
        showForm: true,
        checked: false,
        cur: '',
        info: {},
        form: {
          name: '',
          phone: '',
          email: '',

        },
        list: [
          {
            type: 1,
            top: require('../../assets/imgs/1.png'),
            number: require('../../assets/imgs/n1.png'),
            title: require('../../assets/imgs/g1.png'),
            url: 'game1',
            des: '“上海之根”文化新地标广富林文化遗址，揽尽上海千年文化。让我们开启时空之旅，品味文化之源。地下埋藏着历史的珍宝，网络充满了纷繁的信息，进入小游戏，帮玉兰、沪宝搜集有用的信息吧！'
          },
          {
            type: 2,
            top: require('../../assets/imgs/2.png'),
            number: require('../../assets/imgs/n2.png'),
            title: require('../../assets/imgs/g2.png'),
            url: 'game2',
            des: 'G60科创云廊，是上海科创的“新地标”，也是长三角一体化发展的“新光带”。宛如一艘云中巨舰，托举起“中国智造”的无限未来。建设科创之都，网络安全至关重要，时刻注重防范，为科创产业发展保驾护航！进入小游戏，帮玉兰、沪宝躲避病毒，在网络世界里安全畅游！'
          },
          {
            type: 3,
            top: require('../../assets/imgs/3.png'),
            number: require('../../assets/imgs/n3.png'),
            title: require('../../assets/imgs/g3.png'),
            url: 'game3',
            des: '佘山，钟灵毓秀、人文荟萃、景冠九峰、美不胜收。立于沪上之巅，网罗纷繁信息，警惕信息中的不良因素，是筑牢网络安全防线的重要手段，一起进入小游戏，帮沪宝找出可疑信息吧！'
          },
          {
            type: 4,
            top: require('../../assets/imgs/4.png'),
            number: require('../../assets/imgs/n4.png'),
            title: require('../../assets/imgs/g4.png'),
            url: 'game4',
            des: '泰晤士小镇，一座充满英伦气息的花园社区。湖畔清幽，小镇的宁静需要我们共同守护，共建网络安全，共享网络文明，进入小游戏，跟玉兰、沪宝一起拼出我们的美丽小镇！'
          },
          {
            type: 5,
            top: require('../../assets/imgs/5.png'),
            number: require('../../assets/imgs/n5.png'),
            title: require('../../assets/imgs/g5.png'),
            url: 'game5',
            des: '浦江之首，江岸烟波浩渺，江中帆舫争流。首占潮头，激流勇进，学习网络安全知识，不落人后，进入小游戏，跟玉兰、沪宝一起答题闯关吧！'
          },
          {
            type: 6,
            top: require('../../assets/imgs/6.png'),
            number: require('../../assets/imgs/n6.png'),
            title: require('../../assets/imgs/g6.png'),
            url: 'game6',
            des: '松江大学城，孕育希望的青春乐园。高校汇聚，学府林立，智慧在这里凝结，激情在这里碰撞。大学生是网络用户中的重要群体，守护网络安全，宣传网安知识，离不开青年人的庞大力量。进入小游戏，设计专属于你的网络安全日历！'
          },
        ],
      }
    },
    watch: {},
    computed: {
      ...mapState({ coverShow: state => state.demo.show}),
    },
    methods: {
      ...mapActions({
        playAudio: 'demo/playAudio',
        handleShow: 'demo/handleShow',
      }),
      checkComplete(item) {
        let info = JSON.parse(localStorage.getItem('info'));
        if (info) {
          if(info['g' + item.type]) {
            return true
          } else {
            return  false
          }
        } else {
          return false
        }

      },
      go(url) {
        // this.playAudio(false)
        this.$router.push(url)
      },
      async handleSubmit() {
        if (!this.checked) {
          Toast.fail('请勾选协议')
          return;
        }
        if (!this.form.name) {
          Toast.fail('请填写昵称')
          return;
        }
        if (!this.form.phone) {
          Toast.fail('请填写手机号')
          return;
        }
        if (!this.form.email) {
          Toast.fail('请填写邮箱')
          return;
        }

        let phoneReg=/^[1][2,3,4,5,7,8,9,1,6][0-9]{9}$/;
        if (!phoneReg.test(this.form.phone)) {
          Toast.fail('号码格式错误')
          return;
        }

        let emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if(!emailReg.test(this.form.email)){
          Toast.fail('邮箱格式错误')
          return;
        }

        try {
          const {data} = await api.add(this.form)
          if (data.data) {
            localStorage.setItem('info', JSON.stringify(data.data))
          }
        } catch (e) {
          Toast.fail('提交失败')
        } finally {

        }
        this.showForm = false;
      },
      hideCover() {
        this.showCover =false
        this.handleShow(false)
        // this.playAudio()
        setTimeout(() => {
          this.$refs.home.play()
        }, 100)
      },
      hideTip() {
        this.showTip = false;
      },
      async getInfo (id){
        try {
          const {data} = await api.getInfo({id})
          if (data.data) {
            localStorage.setItem('info', JSON.stringify(data.data))
            this.info = data.data;
          }
          if (!data.code) {
            localStorage.clear()
            this.showForm = true
          }
        } catch (e) {

        } finally {

        }
      },
      initSwiper() {
        let that = this;

        let swiper = new Swiper('#user-swiper', {
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          loopAdditionalSlides: 3,
          loop: true,
          initialSlide: +that.game,
          slideToClickedSlide: true,
          on: {
            click: function(item) {
              let idx = item.clickedIndex % 6;
              let url = that.list[idx].url;
              that.curUrl = url;

              setTimeout(() => {
                document.getElementsByClassName('swiper-slide')[item.clickedIndex].classList.add('slide-on')
              }, 100)
              setTimeout(() => {
                that.go(url)
              }, 400)
            },
            progress: function() {
              // if (that.cur != that.list[this.activeIndex].id) {
              //   that.avatarChange(that.imgList[this.activeIndex])
              // }
              for (let i = 0; i < this.slides.length; i++) {

                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                let modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.1 + 1;
                }
                let scale = 1 - Math.abs(slideProgress) / 9;
                let translate = slideProgress * modify * 30 + 'px';
                let translateY= -Math.abs(slideProgress) * 30 + 'px';
                let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform('translateX(' + translate + ') translateY(' + translateY + ') scale(' + scale + ')');
                slide.css('zIndex', zIndex);
              }
            },
            setTransition: function(swiper, transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i)
                slide.transition(transition);
              }
            },
            transitionEnd: function () {
              // if (that.avatar != that.imgList[this.activeIndex].avatar) {
              //   that.avatarChange(that.imgList[this.activeIndex])
              // }
            }
          },
          lazy: {
            loadPrevNext: true,
          },
        })
      },
    },
    mounted() {
      setTimeout(() => {
        this.loaded = true
      }, 500)
      if (this.$route.query) {
        this.game = this.$route.query.game || 0
        if (this.$route.query.game !== undefined) {
          this.showCover = false;
          this.showTip = false
        }
      }
      if (localStorage.getItem('info')){
        this.showForm = false;
        this.showCover = false
        this.showTip = false
        let info = JSON.parse(localStorage.getItem('info'));
        let id = info.id
        this.getInfo(id);
      }
      this.initSwiper()
      let that = this;
      if (this.coverShow) {
        this.showCover = true
      }
      this.$refs.home.play()
      // window.addEventListener('click', function () {
      //   if (that.$route.name == 'home') {
      //     setTimeout(() => {
      //       that.playAudio(true)
      //     }, 10)
      //   }
      // })
      // window.addEventListener('touchstart', function () {
      //   if (that.$route.name == 'home') {
      //     setTimeout(() => {
      //       that.playAudio(true)
      //     }, 10)
      //   }
      // })
    },
    beforeDestroy() {
      this.$refs.home.pause()
    },
  }
</script>

<style scoped lang="scss">
  .pointer-none{
    pointer-events: none!important;
  }
  .page-container{
    width: 100vw;
    height: 100vh;
    .cover{
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 105;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
      .cover-img{
        width: 100%;
        height: 100%;
        display: block;
        object-position: center;
        object-fit: cover;
      }
      .cover-title{
        width: 678px;
        height: 314px;
        @include center();
        z-index: 10;
        transform: translateY(-80px) scale(1);
        animation: cover-title-ani 5s ease infinite;
      }
      @keyframes cover-title-ani {
        0%{
          transform: translateY(-80px) scale(1);
        }
        50%{
          transform: translateY(-80px) scale(1.06);
        }
        100%{
          transform: translateY(-80px) scale(1);
        }
      }
    }
    .cover-tip{
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
      .cover-img{
        width: 100%;
        height: 100%;
        display: block;
        object-position: center;
        object-fit: cover;
      }
      .cover-start{
        width: 277px;
        height: 176px;
        @include horizontal();
        top: calc(20vh + 620px);
      }
      .cover-text{
        width: 572px;
        height: 647px;
        @include horizontal();
        top: 20vh;
      }
    }
    .cover-show{
      opacity: 1;
      visibility: visible;
    }
    .form-container{
      width: 100%;
      height: 650px;
      @include vertical();
      z-index: 10;
      .form-body{
        width: 656px;
        height: 450px;
        background-image: url("../../assets/imgs/form-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 auto;
        box-sizing: border-box;
        padding-top: 108px;
        .item{
          width: 100%;
          @include clear();
          box-sizing: border-box;
          padding-left: 90px;
          margin-bottom: 20px;
          .label{
            width: 153px;
            height: 78px;
            line-height: 78px;
            font-size: 34px;
            color: #fff;
            float: left;
          }
          .input{
            float: left;
            width: 310px;
            height: 70px;
            background-image: url("../../assets/imgs/input.png");
            background-size: cover;
            background-repeat: no-repeat;
            input{
              width: 100%;
              height: 100%;
              background: none;
              outline: none;
              box-shadow: none;
              border: none;
              box-sizing: border-box;
              padding: 0 20px;
              font-size: 34px;
              color: #333;
            }
          }
        }
        .submit-tip{
          width: 100%;
          text-align: center;
          font-size: 16px;
          color: #fff;

          box-sizing: border-box;
          padding: 0px 35px;
          margin-top: -10px;
        }
      }
      .copyright{
        width: 645px;
        margin: 0 auto;
        padding: 10px 0;
        @include clear();
        display: flex;
        justify-content: space-between;
        .left{
          ::v-deep(.van-checkbox__icon--checked){
            /*.van-icon{*/
            /*  background: #fff;*/
            /*}*/
          }
          ::v-deep(.van-checkbox__icon){
            font-size: 26px;
            background: #fff;

          }
        }
        .right{
          font-size: 14px;
          color: #fff;
          margin-left: 10px;
          span{
            color: #ff0000;
            font-weight: bold;
          }
        }
      }
      .submit{
        width: 100%;
        padding-top: 10px;
        img{
          margin: 0 auto;
          display: block;
          width: 261px;
          height: 90px;
          transition: 0.3s ease;
        }
      }
      .unchecked{
        opacity: 0.5;
      }
    }
    .main-container{
      width: 100vw;
      height: 100vh;
      background-image: url("../../assets/imgs/home.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .page-title{
        width: 590px;
        height: 241px;
        @include horizontal();
        top: 40px;
      }
      .swiper-content{
        width: 100%;
        padding-top: calc(335px + 20px);
        transition: 0.8s ease;
        transform: translateY(60px);
        opacity: 0;
        .swiper{
          width: 100%;
          height: 800px;
          padding-top: 100px;
          .swiper-slide{
            width: 530px;
            height: 800px;
            opacity: 0.5;
            box-sizing: border-box;
            transition: 0.3s;
            position: relative;

            .top{
              width: 100%;
              position: absolute;
              top: -30px;
              height: 310px;
              z-index: 10;
              img{
                @include horizontal();
                bottom: 0;
              }
              .top1{
                width: 267px;
                height: 310px;

              }
              .top2{
                width: 286px;
                height: 249px;
              }
              .top3{
                width: 278px;
                height: 251px;
              }
              .top4{
                width: 279px;
                height: 229px;
              }
              .top5{
                width: 296px;
                height: 252px;
              }
              .top6{
                width: 300px;
                height: 258px;
              }
            }
            .bottom{
              width: 100%;
              min-height: 485px;
              position: absolute;
              top: 160px;
              z-index: 5;
              .bottom-body{
                box-sizing: border-box;
                width: 100%;
                min-height: 485px;
                padding: 120px 30px 0px 40px;
                border-radius: 80px;
                background: #fff;
                padding-bottom: 60px;
                .title{
                  width: calc(151px * 1.2);
                  height: calc(33px * 1.2);
                  position: relative;
                  z-index: 5;
                }
                .des{
                  width: 100%;
                  font-size: 28px;
                  margin-top: 26px;
                  line-height: 34px;
                  color: #595757;
                  position: relative;
                  z-index: 5;
                  padding-bottom: 20px;
                }
                .n{
                  width: 130px;
                  height: 173px;
                  position: absolute;
                  z-index: 3;
                  right: 20px;
                  top: 40px;
                  img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }
              .btn{
                width: 126px;
                height: 125px;
                @include horizontal();
                bottom: -60px;
                z-index: 100;
                img{
                  pointer-events: none;
                }
                .btn-img{
                  width: 126px;
                  height: 125px;
                }
                .btn-arrow{
                  width: 44px;
                  height: 31px;
                  @include center();
                }
                .btn-finish{
                  width: 48px;
                  height: 48px;
                  @include center();
                }
              }
            }
          }
          .slide-on{
            transition: 0.3s ease!important;
            opacity: 1;
            transform: scale(1.15)!important;
          }
          .swiper-slide-active{
            opacity: 1;
            transform: scale(1.1)!important;
            .btn-arrow{
              animation: arrow 3s ease infinite;
            }
            @keyframes arrow {
              0%{
                transform: translateX(0%) ;
              }
              50%{
                transform: translateX(6px) scale(1.1);
              }
              100%{
                transform: translateX(0%);
              }
            }
          }
        }
      }
    }
  }
  .loaded{
    .main-container{
      .swiper-content{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
</style>
<style lang="scss">
  .copy-modal{
    height: 600px;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 20px;
    font-size: 18px;
    div{
      text-indent: 36px;
    }
  }
</style>
