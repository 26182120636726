import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from "@/store";
import { Field, Dialog, Loading, Circle, Checkbox, CheckboxGroup } from 'vant';

router.beforeEach((to, from, next) => {
   try {
     JSON.parse(localStorage.getItem('info'));
   } catch (e) {
     localStorage.setItem('info', '')
   }
  if (to.name !== 'home' && !localStorage.getItem('info')){
    next('home')
  } else {
    next()
  }

})
Vue.config.productionTip = false
Vue.use(Field);
Vue.use(Dialog);

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Loading);
Vue.use(Loading);
Vue.use(Circle);
new Vue({
  store,
  router, // router: router简写
  render: h => h(App),
}).$mount('#app')
