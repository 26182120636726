<template>
  <div class="scroll-body">
    <div class="scroll-wrapper">
      <div class="scroll scroll1">
        <div class="row" v-for="father in 40" :key="father">
          <div class="number" :style="{opacity: getOpacity()}" v-for="son in 20" :key="son">{{Math.floor(Math.random()*1.9)}}</div>
        </div>
      </div>
      <div class="scroll scroll2">
        <div class="row" v-for="father in 40" :key="father">
          <div class="number" :style="{opacity: getOpacity()}" v-for="son in 20" :key="son">{{Math.floor(Math.random()*1.9)}}</div>
        </div>
      </div>
      <div class="scroll scroll3">
        <div class="row" v-for="father in 40" :key="father">
          <div class="number" :style="{opacity: getOpacity()}" v-for="son in 20" :key="son">{{Math.floor(Math.random()*1.9)}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {pxToVw} from '@/utils/index.js'
  export default {
    name: "bg",
    components: {},
    data() {
      return {}
    },
    watch: {},
    computed: {},
    methods: {
      getOpacity() {
        let res = 0.2;
        if (Math.random()*5 <=1 ) {
          res = 1
        }
        if (Math.random()*3 <=1 ) {
          res = 0.5
        }
        return res;
      }
    },
    mounted() {
    },
  }
</script>

<style scoped lang="scss">
  .scroll-body{
    width: 100%;
    height: 1600px;
    perspective: 300px;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    .scroll-wrapper{
      width: 100%;
      height: 100%;
      position: relative;
      transform: rotate3d(1, 0, 0, 6deg) scale(1.3);
      .scroll{
        width: 100%;
        @include clear();
        position: absolute;
        top: 0;
        left: 0;
        .row{
          height: 40px;
          width: 100%;
          overflow: hidden;
        }
        .number{
          width: 5%;
          text-align: center;
          font-size: 32px;
          height: 40px;
          line-height: 40px;
          color: #009ce8;
          float: left;
          font-family: DIN;
          font-weight: bold;
        }
      }
      .scroll1{
        top: 0px;
        animation: scrollHide 5s linear forwards;
      }
      .scroll2{
        left: 0px;
        top: 1600px;
        animation: scroll 5s linear infinite;
      }
      .scroll3{
        left: 0px;
        top: 1600px;

        animation: scroll 5s linear 2.5s infinite;
      }
      @keyframes scrollHide {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-1600px);
          display: none;
        }
      }
      @keyframes scroll {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-1600px);
        }
      }
    }
  }
</style>
