<template>
    <div class="page-container" :class="loaded ? 'loaded' : ''">
        <audio
          ref="game3res"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="../../assets/game3.mp3">
        </audio>
        <div class="form" :class="point == 0? 'form-0': ''">
            <img src="../../assets/imgs/g1-res-shape.png" alt="" class="shape">
            <div class="title" v-if="point != 0">
                <img src="../../assets/imgs/g3-res-title.png" alt="">
                <div class="point"><span>{{point}}</span></div>
            </div>
            <img class="submit" @click="handleSubmit()" src="../../assets/imgs/res-submit.png" alt="">
            <img class="again" @click="goBack()" src="../../assets/imgs/res-again.png" alt="">
            <img class="back" @click="goMenu()" src="../../assets/imgs/res-back.png" alt="">
        </div>
    </div>
</template>

<script>
    import * as api from '@/api';
    export default {
        name: "res",
        data() {
           return {
               loaded: false,
               point: 0
           }
        },
        methods: {
            async update (form){
                try {
                    const {data} = await api.update(form)
                    if (data.code) {
                        let result = data.data;
                        if (result && result.g1 && result.g2 && result.g3 && result.g4 && result.g5 && result.g6) {
                            this.$router.push('complete')
                        } else {
                            this.$router.push('home?game=2')
                        }
                    } else {
                        this.$router.push('home?game=2')
                    }
                } catch (e) {

                } finally {

                }
            },
            handleSubmit() {
                if (localStorage.getItem('info')){
                    let info = JSON.parse(localStorage.getItem('info'));
                    let id = info.id
                    this.update({id, g3: this.point})
                } else {
                    this.$router.push('home?game=2')
                }
            },
            goBack() {
                this.$router.push('game3')
            },
            goMenu() {
                this.$router.push('home?game=2')
            }
        },
        mounted() {
            if (this.$route && this.$route.query) {
                this.point = this.$route.query.point
            }
            setTimeout(() => {
                this.loaded = true
            }, 500)
            this.$refs.game3res.play()
        },
        beforeDestroy() {
            this.$refs.game3res.pause()
        },
    }
</script>

<style scoped lang="scss">
    .page-container{
        width: 100vw;
        height: 100vh;
        background-image: url("../../assets/imgs/g3-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        .form{
            width: 580px;
            height: 1020px;
            background-size: cover;
            background-repeat: no-repeat;
            @include center();
            transition: 1s ease;
            opacity: 0;
            transform: translateY(100px);
            background-image: url("../../assets/imgs/g3-res.png");
            .title{
                width: 383px;
                height: 101px;
                @include horizontal();
                top: 420px;
                z-index: 15;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .point{
                width: 50px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                color: #333333;
                position: absolute;
                right: 82px;
                top: 0px;
                z-index: 10;
                text-align: center;
                span{
                    font-size: 50px;
                    font-weight: bold;
                    font-family: DIN;
                    position: relative;
                    display: inline-block;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .submit{
                width: 240px;
                height: 73px;
                @include horizontal();
                bottom: 314px;
                z-index: 15;
                cursor: pointer;
            }
            .again{
                width: 240px;
                height: 73px;
                @include horizontal();
                bottom: 216px;
                z-index: 15;
                cursor: pointer;
            }
            .back{
                width: 269px;
                height: 73px;
                @include horizontal();
                bottom: 113px;
                z-index: 15;
                cursor: pointer;
            }
        }
        .form-0{
            background-image: url("../../assets/imgs/g3-res-0.png")!important;
        }
        .shape{
            width: 724px;
            height: 488px;
            position: absolute;
            top: -100px;
            left: -68px;
            z-index: 1;
            transition: 1s ease;
            transition-delay: 0.5s;
            opacity: 0;
            transform: translateY(-30px);
        }
    }
    .loaded{
        .form{
            transform: translateY(0);
            opacity: 1;
        }
        .shape{
            transform: translateY(0);
            opacity: 1;
        }
    }
</style>
