<template>
    <div>
        <h2>demo</h2>
        <h3>store</h3>
        <h4>count: {{count}}</h4>
        <h4>getCount: {{getAge}}</h4>
        <h4><button @click="increment">inCream</button></h4>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    import { getListAPI } from '@/api';
    export default {
        name: "demo",
        computed: {
            ...mapState({ count: state => state.demo.count, }),
            ...mapGetters({ getAge: 'demo/getAge', }),
        },
        methods: {
            ...mapActions({ increment: 'demo/increment', }),
            async getList() {
                try{
                    const {data} = await getListAPI();
                    console.log(data);
                } catch (e) {
                    console.log(e);
                } finally {
                    console.log('finish');
                }
            }
        },
        async mounted() {
            await this.getList();
        }
    }
</script>

<style scoped lang="scss"></style>
