<template>
    <div class="page-container">
        <audio
          ref="game4"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="../../assets/game4.mp3">
        </audio>
        <div class="cover" v-show="showCover">
            <img class="bg" src="../../assets/imgs/game4.jpg" alt="">
            <img class="start" @click="hideCover" src="../../assets/imgs/game4-start.png" alt="">
        </div>
        <div class="main-mask"  v-show="!showCover" v-if="startRemain > 0">
            <div class="mask-remain">
                {{startRemain}}
            </div>
        </div>
        <div class="main-container" v-show="!showCover">
            <div class="point">得分：<span>{{point}}</span></div>
            <div class="puzzle-container">
                <div class="puzzle-preview">
                    <img v-if="level == 1" :src="puzzle1" alt="">
                    <img v-if="level == 2" :src="puzzle2" alt="">
                </div>
                <div class="puzzle-box">
                    <div class="bonus" :class="showBonus ? 'bonus-on' : ''">+{{calc}}</div>
                    <div class="progress">
                        <div class="circle-container">
                            <van-circle
                              v-model="currentRate"
                              :rate="rate"
                              layer-color="#d4d6d8"
                              :speed="100 / 60"
                              :stroke-width="80"
                              :color="gradientColor"
                              :text="remain + 's'"
                              :size="pxToVw(110)"
                              :clockwise="false"
                            />
                        </div>

                    </div>
                    <div class="puzzle" v-show="showPuzzle">
                        <div class="line line1" style="height: 1px"></div>
                        <div class="line line2" style="height: 1px"></div>
                        <div class="line line3" style="width: 1px"></div>
                        <div class="line line4" style="width: 1px"></div>
                        <draggable v-model="listClone" :options="{ sort: true }" class="grid" @end="onEnd">
                            <div v-for="item in listClone" :key="item.id" class="grid-item"></div>
                        </draggable>
                        <div class="grid" style="pointer-events: none">
                            <div class="grid-item" v-for="item in list" :key="item.id" :style="getImageStyle(item.id)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rocket"></div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    import {pxToVw} from '@/utils/index.js'
    export default {
        name: "index",
        components: {
            draggable
        },
        data() {
            return {
                startRemain: 3,
                startTimer: null,
                showCover: true,
                remainTimer: null,
                showPuzzle: true,
                rate: 100,
                currentRate: 100,
                remain: 60,
                point: 0,
                calc: 0,
                showBonus: false,
                gradientColor: {'0%': '#3fecff', '100%': '#561fe0',},
                listClone: [],
                list: [],
                level: 1,
                puzzle1: require('../../assets/imgs/g4-puzzle1.jpg'),
                puzzle2: require('../../assets/imgs/g4-puzzle2.jpg'),
                puzzle3: require('../../assets/imgs/g4-puzzle3.jpg'),
            }
        },
        methods: {
            pxToVw,
            checkOrder() {
                let res = false;
                for (let i = 0; i < this.list.length - 1; i++) {
                    if (this.list[i].id >= this.list[i + 1].id) {
                        res = false;
                        return;
                    }
                }
                res = true
                return res;
            },
            getImageStyle(index) {
                const x = (index - 1) % 3; // 计算x轴位置，范围是0到2
                const y = Math.floor((index - 1) / 3); // 计算y轴位置，范围是0到2

                const positionX = x * 50 + '%'; // 将x坐标转换为百分比，每个网格宽度为33.33%
                const positionY = y * 50 + '%'; // 将y坐标转换为百分比，每个网格高度为33.33%

                return {
                    backgroundImage: `url(${this['puzzle' + this.level]})`,
                    backgroundPosition: `${positionX} ${positionY}`,
                };
            },
            onEnd(evt) {
                let {newIndex, oldIndex} = evt;
                let arr = JSON.parse(JSON.stringify(this.list));
                [arr[newIndex], arr[oldIndex]] = [arr[oldIndex], arr[newIndex]];
                this.list = arr;
                this.listClone = arr;
                let res = this.checkOrder();
                if (res) {
                    this.showBonus = true;
                    this.calc = 10;
                    this.point = this.point + 10
                    setTimeout(() => {
                        this.showBonus = false
                        this.calc= 0;
                    }, 1200)
                    if (this.level == 2) {
                        this.finishGame()
                    } else {
                        this.showPuzzle = false;
                        this.level = this.level + 1
                        this.randomPuzzle()
                        this.showPuzzle = true;
                    }
                }
            },
            hideCover() {
                this.showCover = false;
                this.handleStartRemain();
                setTimeout(() => {
                    this.$refs.game4.play()
                }, 100)
            },
            handleStartRemain() {
                if (this.startTimer) {
                    clearInterval(this.startTimer)
                    this.startTimer = null;
                }
                this.startTimer = setInterval(() => {
                    if (this.startRemain <= 1 ) {
                        this.startRemain = this.startRemain  - 1;
                        clearInterval(this.startTimer)
                        this.startTimer = null;
                        this.startGame();
                    } else {
                        this.startRemain = this.startRemain  - 1;
                    }
                }, 1000)
            },
            finishGame() {
                this.$router.push({
                    name: 'game4Res',
                    query: {point: this.point}
                })
            },
            startGame() {
                if (this.remainTimer) {
                    clearInterval(this.remainTimer)
                    this.remainTimer = null;
                }
                setTimeout(() => {
                    this.rate = 0;
                }, 1000)
                this.remainTimer = setInterval(() => {
                    if (this.remain <=0 ) {
                        clearInterval(this.remainTimer)
                        this.remainTimer = null;
                        this.finishGame();
                    } else {
                        this.remain = this.remain  - 1;
                    }
                }, 1000)
            },
            randomPuzzle() {
                let arr = [
                    { id: 1 },
                    { id: 2 },
                    { id: 3 },
                    { id: 4 },
                    { id: 5 },
                    { id: 6 },
                    { id: 7 },
                    { id: 8 },
                    { id: 9 },]
                arr.sort(() => Math.random() - 0.5);
                this.list = arr;
                this.listClone = arr;
            }
        },
        created() {
            this.randomPuzzle();
        },
        mounted() {
            this.$refs.game4.play()
        },
        beforeDestroy() {
            clearInterval(this.remainTimer)
            this.remainTimer = null;
            clearInterval(this.startTimer);
            this.startTimer = null;
            this.$refs.game4.pause()
        },
    }
</script>

<style scoped lang="scss">
    .page-container{
        width: 100vw;
        min-height: 100vh;
        .cover{
            width: 100vw;
            min-height: 100vh;
            background: #00010b;
            position: relative;
        }
        .bg{
            width: 100%;
        }
        .start{
            @include horizontal();
            top: 940px;
            width: 588px;
            height: 509px;
            transform: translateX(50px);
        }
        .main-container{
            width: 100vw;
            min-height: 100vh;
            position: relative;
            background: url("../../assets/imgs/g4-bg.jpg") no-repeat center;
            background-size: cover;
            .point{
                position: absolute;
                width: 200px;
                left: 60px;
                top: 60px;
                z-index: 10;
                font-size: 30px;
                color: #fff;
                span{
                    font-size: 36px;
                    font-weight: bold;
                    font-family: DIN;
                }
            }
            .progress{
                position: absolute;
                width: 127px;
                height: 127px;
                right: -55px;
                top: -55px;
                z-index: 10;
                background: #fff;
                border-radius: 50%;
                .circle-bg{
                    width: 106px;
                    height: 106px;
                    @include center();
                    border: 4px solid #d4d6d8;
                    border-radius: 50%;
                    box-sizing: border-box;
                    z-index: 5;
                }
                .circle-container{
                    width: 110px;
                    height: 110px;
                    @include center();
                    z-index: 10;
                    ::v-deep(.van-circle__text){
                        color: #4377ff;
                        font-size: 36px;
                        font-family: DIN;
                        font-weight: bold;
                    }
                }
            }
            .puzzle-container{
                @include horizontal();
                width: 614px;
                height: 900px;
                top: 32vh;
                .puzzle-preview{
                    width: 200px;
                    height: 200px;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    img{
                        width: 200px;
                        height: 200px;
                    }
                }
                .puzzle-box{
                    width: 614px;
                    height: 671px;
                    z-index: 5;
                    background: url("../../assets/imgs/g4-puzzle-box.png") no-repeat center;
                    background-size: cover;
                    position: relative;
                    .puzzle{
                        @include center();
                        width: 502px;
                        height: 502px;
                        .line{
                            position: absolute;
                            background: #fff;
                            z-index: 20;
                            pointer-events: none;
                        }
                        .line1{
                            width: 502px;
                            top: calc(100% / 3);
                            left: 0;
                        }
                        .line2{
                            width: 502px;
                            top: calc(100% / 3 * 2);
                            left: 0;
                        }
                        .line3{
                            height: 502px;
                            top: 0;
                            left: calc(100% / 3);
                        }
                        .line4{
                            height: 502px;
                            top: 0;
                            left: calc(100% / 3 * 2);
                        }
                        .grid {
                            @include center();
                            display: grid;
                            grid-template-columns: repeat(3, calc(502px / 3));
                            grid-template-rows: repeat(3, calc(502px / 3));
                            border-radius: 10px;
                            overflow: hidden;
                            .grid-item{
                                background-size: calc(300%) calc(300%);
                                background-repeat: no-repeat;
                            }
                        }
                    }
                    .bonus{
                        width: 100px;
                        text-align: center;
                        font-size: 60px;
                        font-weight: bold;
                        color: #fff;
                        font-family: DIN;
                        @include horizontal();
                        z-index: 15;
                        top: -50px;
                        text-shadow: 0 0 10px rgba(0,0,0,0.3);
                        opacity: 0;
                    }
                    .bonus-on{
                        animation: bonus 1.2s ease forwards;
                    }
                    @keyframes bonus {
                        0%{
                            transform: translateY(0px);
                            opacity: 1;
                        }
                        100%{
                            transform: translateY(-100px);
                            opacity: 0;
                        }
                    }
                }
            }

            .rocket{
                width: 151px;
                height: 170px;
                position: absolute;
                right: 0;
                top: 1175px;
                z-index: 10;
                background: url("../../assets/imgs/g4-rocket.png") no-repeat center;
                background-size: cover;
            }
        }
    }
</style>
