<template>
    <div class="page-container">
        <audio
          ref="game2"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="../../assets/game2.mp3">
        </audio>
        <div class="cover" v-show="showCover">
            <img class="bg" src="../../assets/imgs/game2.jpg" alt="">
            <img class="start"  src="../../assets/imgs/game2-start.png"  @click="hideCover" alt="">
        </div>
        <div class="main-mask"  v-show="!showCover" v-if="startRemain > 0">
            <div class="mask-remain">
                {{startRemain}}
            </div>
        </div>
        <div class="main-container" v-show="!showCover">
            <img src="../../assets/imgs/g2-title.png" class="title" alt="">
            <div class="point">得分：<span>{{point}}</span></div>
            <div class="progress">
                <div class="circle-container">
                    <van-circle
                            v-model="currentRate"
                            :rate="rate"
                            layer-color="#d4d6d8"
                            :speed="100 / 60"
                            :stroke-width="80"
                            :color="gradientColor"
                            :text="remain + 's'"
                            :size="pxToVw(110)"
                            :clockwise="false"
                    />
                </div>
            </div>
            <div class="game-container">
                <ScrollBody></ScrollBody>
                <div class="people-container" :class="curList">
                    <img src="../../assets/imgs/g2-people.png" ref="people" class="people" alt="">
                </div>
                <div class="list list1" @click="curList = 'curList1'">
                    <img ref="img" v-for="item in list1" :src="item.url" :key="item.id" :style="{width: pxToVw(item.w) + 'px', height: pxToVw(item.h) + 'px'}" alt="">
                </div>
                <div class="list list2" @click="curList = 'curList2'">
                    <img ref="img" v-for="item in list2" :src="item.url" :key="item.id" :style="{width: pxToVw(item.w) + 'px', height: pxToVw(item.h) + 'px'}" alt="">
                </div>
                <div class="list list3" @click="curList = 'curList3'">
                    <img ref="img" v-for="item in list3" :src="item.url" :key="item.id" :style="{width: pxToVw(item.w) + 'px', height: pxToVw(item.h) + 'px'}" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {uniqueId} from 'lodash';
    import {pxToVw} from '@/utils/index.js'
    import ScrollBody from './bg.vue'
    export default {
        name: "index",
        components: {ScrollBody},
        data() {
            return {
                startRemain: 3,
                startTimer: null,
                showCover: true,
                remainTimer: null,
                rate: 100,
                currentRate: 100,
                remain: 60,
                point: 0,
                calc: 0,
                showBonus: false,
                gradientColor: {'0%': '#3fecff', '100%': '#561fe0',},
                listClone: [],
                list1: [],
                list2: [],
                list3: [],
                curList: '',
                bug1: require('../../assets/imgs/g2-el1.png'),
                bug2: require('../../assets/imgs/g2-el2.png'),
            }
        },
        methods: {
            pxToVw,
            hideCover() {
                this.showCover = false;
                this.handleStartRemain();
                setTimeout(() => {
                    this.$refs.game2.play()
                }, 100)
            },
            handleStartRemain() {
                if (this.startTimer) {
                    clearInterval(this.startTimer)
                    this.startTimer = null;
                }
                this.startTimer = setInterval(() => {
                    if (this.startRemain <= 1 ) {
                        this.startRemain = this.startRemain  - 1;
                        clearInterval(this.startTimer)
                        this.startTimer = null;
                        this.startGame();
                    } else {
                        this.startRemain = this.startRemain  - 1;
                    }
                }, 1000)
            },
            finishGame() {
                this.$router.push({
                    name: 'game2Res',
                    query: {point: this.point}
                })
            },
            startGame() {
                if (this.remainTimer) {
                    clearInterval(this.remainTimer)
                    this.remainTimer = null;
                }
                setTimeout(() => {
                    this.rate = 0;
                }, 1000)
                this.remainTimer = setInterval(() => {
                    if (this.remain <=0 ) {
                        clearInterval(this.remainTimer)
                        this.remainTimer = null;
                        this.finishGame();
                    } else {
                        this.remain = this.remain  - 1;
                    }
                }, 1000)

                this.timer = setInterval(() => {
                    if (this.checkGrab()) {
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 16)
                this.addBug()
            },
            checkGrab() {
                let people = this.$refs.people;
                const peopleRect = people.getBoundingClientRect();
                let imgs = this.$refs.img;
                let code = 0;
                if (imgs){
                    imgs.forEach(el => {
                        const elRect = el.getBoundingClientRect();
                        const overlap =
                          peopleRect.left < elRect.right &&
                          peopleRect.right > elRect.left &&
                          peopleRect.top < elRect.bottom &&
                          peopleRect.bottom > elRect.top;
                        if (overlap) {
                            code = 1
                        }
                    })
                    if (code) {
                        this.finishGame()
                        return true;
                    } else {
                        return false
                    }
                }

            },
            addBug() {
                let func = () => {
                    let lIdx = Math.floor(Math.random()*2.99) + 1;
                    setTimeout(() => {
                        let id = uniqueId();
                        let who = Math.random() >= 0.5 ? true: false
                        this['list' + lIdx].push({
                            id: id,
                            url: who ? this.bug1 : this.bug2,
                            w: who ? 89: 81,
                            h: who ?99: 92
                        })
                        setTimeout(() => {
                            let idx = this['list' + lIdx].findIndex(item => item.id == id);
                            if (idx !== -1) {
                                this['list' + lIdx].splice(idx, 1)
                                this.point = this.point + 1;
                            }
                        }, 3000)
                    },  (2 - Math.random() * 1) * 1000)
                }
                func();
                setInterval(() => {
                    func();
                }, 1300)
            }
        },
        created() {

        },
        mounted() {
            this.$refs.game2.play()
        },
        beforeDestroy() {
            clearInterval(this.remainTimer)
            this.remainTimer = null;
            clearInterval(this.timer);
            this.timer = null;
            clearInterval(this.startTimer);
            this.startTimer = null;
            this.$refs.game2.pause()
        },
    }
</script>

<style scoped lang="scss">
    .page-container{
        width: 100vw;
        min-height: 100vh;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            border: 1px solid #ccc;
        }
        .cover{
            width: 100vw;
            min-height: 100vh;
            background: #180673;
            position: relative;
        }
        .bg{
            width: 100%;
        }
        .start{
            @include horizontal();
            top: 1230px;
            width: 337px;
            height: 133px;
        }
        .main-container{
            width: 100vw;
            height: 100vh;
            position: relative;
            background:#190061;
            .game-container{
                width: calc(100vw - 58px - 58px);
                height: calc(100vh - 200px);
                box-sizing: border-box;
                @include center();
                background-image: linear-gradient(180deg, #040851, #0027d6);
                overflow: hidden;
                border-radius: 10px;
                @include clear();
                .people-container{
                    width: 33.33%;
                    height: 200px;
                    position: absolute;
                    z-index: 10;
                    top: 88px;
                    left: 0;
                    transition: 0.2s ease;
                    img{
                        width: 100%;
                        height: 100%;
                        object-position: center;
                        object-fit: contain;
                    }
                }
                .curList1{
                    transform: translateX(0%);
                }
                .curList2{
                    transform: translateX(calc(613px / 3));
                }
                .curList3{
                    transform: translateX(calc(613px / 3 * 2));
                }
                .list{
                    width: 33.33%;
                    float: left;
                    height: 100%;
                    position: relative;
                    z-index: 10;
                    perspective: 300px;
                    border-right: 1px solid rgba(255,255,255,0.6);
                    box-sizing: border-box;
                    &:hover{
                        animation: list 0.3s ease;
                    }
                    img{
                        display: block;
                        @include horizontal();
                        top: calc(100vh - 200px);
                        animation: bug 3s linear forwards;
                    }
                }
                .list3{
                    border-right: none;
                }
                @keyframes bug {
                    0% {
                        transform: translateY(0);
                    }
                    100% {
                        transform: translateY(calc(200px - 100vh - 200px));
                        display: none;
                    }
                }
                @keyframes list {
                    0%{
                        background: rgba(255,255,255,0);
                    }
                    50%{
                        background: rgba(255,255,255,0.15);
                    }
                    100%{
                        background: rgba(255,255,255,0);
                    }
                }
            }
            .title{
                width: 750px;
                height: 272px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 15;

            }

            .point{
                position: absolute;
                width: 200px;
                left: 60px;
                top: 15px;
                z-index: 10;
                font-size: 30px;
                color: #fff;
                span{
                    font-size: 36px;
                    font-weight: bold;
                    font-family: DIN;
                }
            }
            .progress{
                position: absolute;
                width: 127px;
                height: 127px;
                right: 58px;
                top: 175px;
                z-index: 10;
                background: #fff;
                border-radius: 50%;
                .circle-bg{
                    width: 106px;
                    height: 106px;
                    @include center();
                    border: 4px solid #d4d6d8;
                    border-radius: 50%;
                    box-sizing: border-box;
                    z-index: 5;
                }
                .circle-container{
                    width: 110px;
                    height: 110px;
                    @include center();
                    z-index: 10;
                    ::v-deep(.van-circle__text){
                        color: #4377ff;
                        font-size: 36px;
                        font-family: DIN;
                        font-weight: bold;
                    }
                }
            }
        }
    }
</style>
