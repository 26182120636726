const demo = {
    namespaced: true,
    state: {
        count: 0,
        age: 1,
        play: false,
        show: true
    },
    getters: {
        getAge(state) {
            return state.age;
        }
    },
    mutations: {
        increment(state) {
            state.count++;
        },
        playAudio(state, status) {
            state.play = status
        },
        handleShow(state, status) {
            state.show = status
        }
    },
    actions: {
        increment({ commit }, status) {
            commit("increment", status);
        },
        playAudio({ commit }, status) {
            commit("playAudio", status);
        },
        handleShow({ commit }, status) {
            commit("handleShow", status);
        }
    }
};

export default demo;
