<template>
    <div class="page-container">
        <audio
          ref="game5"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="../../assets/game5.mp3">
        </audio>
        <div class="cover" v-show="showCover" @click="hideCover">
        </div>
        <div class="main-mask"  v-show="!showCover" v-if="startRemain > 0">
            <div class="mask-remain">
                {{startRemain}}
            </div>
        </div>
        <div class="main-container" v-show="!showCover">
            <div class="point">得分：<span>{{point}}</span></div>
            <div class="game-container" v-if="list.length">
                <div class="bonus" :class="showBonus ? 'bonus-on' : ''">{{calc > 0 ? '+': ''}}{{calc}}</div>
                <div class="remain-bar">
                    <div class="number-bar">
                        第 {{level + 1}} 题
                    </div>
                    <div class="progress-container">
                        <div class="progress">
                            <div class="circle-container">
                                <van-circle
                                        v-if="!rate"
                                        v-model="currentRate"
                                        :rate="rate"
                                        layer-color="#3bb4ff"
                                        :speed="100 / 30"
                                        :stroke-width="120"
                                        :color="gradientColor"
                                        :text="String(remain)"
                                        :size="pxToVw(138)"
                                        :clockwise="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="q-box">
                    {{list[level].name}}
                </div>
                <div class="a-box">
                    <div class="a-item" :class="answer.indexOf('a') > -1 ? 'a-item-on': ''" @click="confirm('a', list[level].type2)">
                        <div class="left">
                            {{list[level].c ? 'A': '√'}}
                        </div>
                        <div class="right">{{list[level].a}}</div>
                    </div>
                    <div class="a-item" :class="answer.indexOf('b') > -1 ? 'a-item-on': ''" @click="confirm('b', list[level].type2)">
                        <div class="left" style="background: #15587c">
                            {{list[level].c ? 'B': '×'}}
                        </div>
                        <div class="right" style="background: #15587c">{{list[level].b}}</div>
                    </div>
                    <div class="a-item" :class="answer.indexOf('c') > -1 ? 'a-item-on': ''" v-if="list[level].c && list[level].c != '是' &&  list[level].c != '否'" @click="confirm('c', list[level].type2)">
                        <div class="left" style="background: #ec6b39">C</div>
                        <div class="right" style="background: #ec6b39">{{list[level].c}}</div>
                    </div>
                    <div class="a-item" :class="answer.indexOf('d') > -1 ? 'a-item-on': ''" v-if="list[level].d && list[level].d != '是' &&  list[level].d != '否'" @click="confirm('d', list[level].type2)">
                        <div class="left" style="background: #dc466f">D</div>
                        <div class="right" style="background: #dc466f">{{list[level].d}}</div>
                    </div>
                </div>
                <div class="confirm" @click="next()">
                    <img src="../../assets/imgs/g5-confirm.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as api from '@/api';
    import {pxToVw} from '@/utils/index.js'
    import { Toast } from 'vant'

    export default {
        name: "index",
        data() {
            return {
                startRemain: 3,
                startTimer: null,
                showCover: true,
                remainTimer: null,
                rate: 100,
                currentRate: 100,
                remain: 30,
                point: 0,
                calc: 0,
                showBonus: false,
                gradientColor: {'0%': '#ffd243', '100%': '#ffd243',},
                level: 0,
                list: [],
                answer: []
            }
        },
        methods: {
            pxToVw,
            hideCover() {
                this.showCover = false;
                this.handleStartRemain();
                setTimeout(() => {
                    this.$refs.game5.play()
                }, 100)
            },
            handleStartRemain() {
                if (this.startTimer) {
                    clearInterval(this.startTimer)
                    this.startTimer = null;
                }
                this.startTimer = setInterval(() => {
                    if (this.startRemain <= 1 ) {
                        this.startRemain = this.startRemain  - 1;
                        clearInterval(this.startTimer)
                        this.startTimer = null;
                        this.startGame();
                    } else {
                        this.startRemain = this.startRemain  - 1;
                    }
                }, 1000)
            },
            finishGame() {
                this.$router.push({
                    name: 'game5Res',
                    query: {point: this.point}
                })
            },
            startGame() {
                if (this.remainTimer) {
                    clearInterval(this.remainTimer)
                    this.remainTimer = null;
                }
                this.rate = 0;
                this.remainTimer = setInterval(() => {
                    if (this.remain <=0 ) {
                        clearInterval(this.remainTimer)
                        this.remainTimer = null;
                        this.next();
                    } else {
                        this.remain = this.remain  - 1;
                    }
                }, 1000)
            },
            next() {
                Toast.clear();
                let func = () => {
                    this.answer = [];
                    clearInterval(this.remainTimer)
                    this.remainTimer = null;
                    if (this.level == 9) {
                        this.finishGame();
                        return;
                    }
                    this.remain = 30;
                    this.level = this.level + 1;
                    this.currentRate = 100
                    this.rate = 100;
                    setTimeout(() => {
                        this.rate = 0
                    })

                    this.remainTimer = setInterval(() => {
                        if (this.remain <=0 ) {
                            clearInterval(this.remainTimer)
                            this.remainTimer = null;
                            this.next();
                        } else {
                            this.remain = this.remain  - 1;
                        }
                    }, 1000)
                };
                if (this.answer.length == this.list[this.level].answer.split(',').length) {
                    let error = false;
                    this.answer.forEach(item => {
                        if (this.list[this.level].answer.indexOf(item) === -1) {
                            error = true
                        }
                    })
                    if (!error) {
                        this.showBonus = true;
                        this.calc = 1
                        setTimeout(() => {
                            this.showBonus = false
                            this.calc= 0;
                        }, 1200)
                        this.point = this.point + 1;
                    }
                    if(error) {
                        let finAn = this.list[this.level].answer;
                        let finAn2 = this.list[this.level].c ? finAn : this.list[this.level].answer == 'a' ? '√': '×'
                        // if (this.list[this.level].c && this.list[this.level].c != '是' && this.list[this.level].c != '否') {
                        //     finAn = this.list[this.level].answer
                        // } else {
                        //     finAn = this.list[this.level].answer == 'a' ? '√': '×'
                        // }
                        Toast.fail({
                            className: 'custom-toast',
                            overlay: true,
                            duration: 3000, // 持续展示 toast
                            message: '回答错误，正确答案是' + finAn2.toUpperCase() + "：" + this.list[this.level][finAn] || finAn,
                            icon: 'cross',
                        });
                        setTimeout(() => {
                            func()
                        }, 3000)
                    } else {
                        Toast.success({
                            overlay: true,
                            duration: 1500, // 持续展示 toast
                            message: '回答正确',
                        });
                        setTimeout(() => {
                            func()
                        }, 1500)
                    }
                } else {
                    let finAn = this.list[this.level].answer;
                    let finAn2 = this.list[this.level].c ? finAn : this.list[this.level].answer == 'a' ? '√': '×'
                    Toast.fail({
                        className: 'custom-toast',
                        icon: 'cross',
                        overlay: true,
                        duration: 3000, // 持续展示 toast
                        message: '回答错误，正确答案是' + finAn2.toUpperCase() + "：" + this.list[this.level][finAn] || finAn,
                    });
                    setTimeout(() => {
                        func()
                    }, 3000)
                }

            },
            confirm(a, type){
                if (type === '多选题') {
                    if (this.answer.indexOf(a) == -1) {
                        this.answer.push(a);
                    } else {
                        this.answer.splice(this.answer.indexOf(a), 1);
                    }
                } else {
                    this.answer = [a];
                }
                // if (this.list[this.level].answer.indexOf(a) > -1) {
                //     this.showBonus = true;
                //     this.calc = 1
                //     setTimeout(() => {
                //         this.showBonus = false
                //         this.calc= 0;
                //     }, 1200)
                //     this.point = this.point + 1;
                // }
                // this.next()
            },
            async getList (){
                try {
                    const {data} = await api.list()
                    if (data.code) {
                        this.list = data.data.map(item => {
                            item.name.replace('单选题', '');
                            return item
                        });
                    }
                } catch (e) {

                } finally {

                }
            },
        },
        created() {

        },
        mounted() {
            this.getList();
            this.$refs.game5.play()
        },
        beforeDestroy() {
            clearInterval(this.remainTimer)
            this.remainTimer = null;
            clearInterval(this.startTimer);
            this.startTimer = null;
            this.$refs.game5.pause()
        },
    }
</script>
<style>
    .custom-toast{
        width: 400px;
    }
</style>
<style scoped lang="scss">
    .page-container{
        width: 100vw;
        min-height: 100vh;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            border: 1px solid #ccc;
        }
        .cover{
            width: 100vw;
            min-height: 100vh;
            position: relative;
            background-image: url('../../assets/imgs/game5.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

        }
        .main-container{
            width: 100vw;
            min-height: 100vh;
            position: relative;
            background-image: url("../../assets/imgs/g5-bg.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            .bonus{
                width: 100px;
                text-align: center;
                font-size: 60px;
                font-weight: bold;
                color: #fff;
                font-family: DIN;
                @include horizontal();
                z-index: 15;
                top: -50px;
                text-shadow: 0 0 10px rgba(0,0,0,0.3);
                opacity: 0;
            }
            .bonus-on{
                animation: bonus 1.2s ease forwards;
            }
            @keyframes bonus {
                0%{
                    transform: translateY(0px);
                    opacity: 1;
                }
                100%{
                    transform: translateY(-100px);
                    opacity: 0;
                }
            }
            .point{
                position: absolute;
                width: 200px;
                left: 60px;
                top: 15px;
                z-index: 10;
                font-size: 30px;
                color: #fff;
                span{
                    font-size: 36px;
                    font-weight: bold;
                    font-family: DIN;
                }
            }

            .game-container{
                width: 600px;
                /*height: 885px;*/
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                //@include center();
                margin-left: 75px;
                padding-top: 12vh;
                .remain-bar{
                    width: 100%;
                    height: 166px;
                    position: relative;
                    .progress-container{
                        width: 166px;
                        height: 166px;
                        border-radius: 50%;
                        background: #fff;
                        position: relative;
                        z-index: 10;
                        .progress{
                            @include center();
                            width: 138px;
                            height: 138px;
                            z-index: 10;
                            background: #fff;
                            border-radius: 50%;
                            .circle-container{
                                width: 138px;
                                height: 138px;
                                @include center();
                                z-index: 10;
                                background: #3bb4ff;
                                border-radius: 50%;
                                ::v-deep(.van-circle){
                                    color: #fff;
                                    font-size: 60px;
                                    font-family: DIN;
                                    font-weight: bold;
                                    line-height: 138px;
                                    .van-circle__text{
                                        color: #fff;
                                        font-size: 60px;
                                        font-family: DIN;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                    .number-bar{
                        width: 100%;
                        height: 128px;
                        border-radius: 128px;
                        background: #ffe497;
                        @include vertical();
                        z-index: 1;
                        line-height: 128px;
                        text-align: center;
                        box-sizing: border-box;
                        padding-left: 120px;
                        font-weight: bold;
                        color: #0e131f;
                        font-size: 32px;
                    }

                }
                .q-box{
                    width: 100%;
                    border-radius: 26px;
                    background: #fff;
                    margin-top: 80px;
                    box-sizing: border-box;
                    padding: 50px;
                    font-size: 32px;
                    line-height: 46px;
                    letter-spacing: 2px;
                    color: #444443;
                }
                .a-box{
                    width: 100%;
                    padding-top: 80px;
                    padding-bottom: 80px;
                    @include clear();
                    .a-item{
                        width: 100%;
                        @include clear();
                        margin-bottom: 24px;
                    }
                    .a-item-on{
                        border-radius: 26px;
                        .left{
                            box-shadow: 0 0 15px 10px rgba(#fff, 0.5) inset;
                        }
                        .right{
                             box-shadow: 0 0 15px 10px rgba(#fff, 0.5) inset;
                         }
                    }
                    .left{
                        width: 52px;
                        height: 52px;
                        background: #26a283;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 52px;
                        font-weight: bold;
                        font-size: 32px;
                        color: #fff;
                        font-family: DIN;
                        float: left;
                    }
                    .right{
                        width: calc(100% - 12px - 52px);
                        float: right;
                        font-size: 28px;
                        color: #fff;
                        background: #26a283;
                        box-sizing: border-box;
                        padding: 5px 20px;
                        line-height: 42px;
                        border-radius: 26px;
                    }

                }
                .confirm{
                    width: 483px;
                    height: 82px;
                    margin: 0 auto;
                    img{
                        width: 483px;
                        height: 82px;
                    }
                }
            }
        }
    }
</style>
