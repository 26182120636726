import http from '@/axios/http';

export function add(params){
    return http.post('app/visitor/add', params)
}

export function getInfo(params){
    return http.post('app/visitor/info', params)
}

export function update(params){
    return http.post('app/visitor/update', params)
}

export function getParams(params){
    return http.post('app/visitor/getParams', params)
}

export function list(params){
    return http.post('app/goods/list', params)
}

export function upFile(params){
    return http.post('app/visitor/file', params)
}

