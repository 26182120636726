export const pointMap = {
  trip: 8,
  call: 10,
  charge: 10,
  live: 2
}

export function pxToVw(px) {
  let res = 0
  let base = 750
  let cur = window.innerWidth
  res = (px / base) * cur
  return res
}
