<template>
    <div class="page-container" :class="loaded ? 'loaded' : ''">
        <audio
          ref="game5res"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="../../assets/game5.mp3">
        </audio>
        <div class="form">
            <img src="../../assets/imgs/g5-form.png" alt="" class="form-box">
            <img src="../../assets/imgs/g5-star.png" class="star" alt="">
            <div class="point"><span>{{point}}</span></div>
            <img class="submit" @click="handleSubmit()" src="../../assets/imgs/g5-submit.png" alt="">
            <img class="again" @click="goBack()" src="../../assets/imgs/g5-again.png" alt="">
            <img class="back" @click="goMenu()" src="../../assets/imgs/g5-back.png" alt="">
        </div>
    </div>
</template>

<script>
    import * as api from '@/api';
    export default {
        name: "res",
        data() {
           return {
               loaded: false,
               point: 0
           }
        },
        methods: {
            async update (form){
                try {
                    const {data} = await api.update(form)
                    if (data.code) {
                        let result = data.data;
                        if (result && result.g1 && result.g2 && result.g3 && result.g4 && result.g5 && result.g6) {
                            this.$router.push('complete')
                        } else {
                            this.$router.push('home?game=4')
                        }
                    } else {
                        this.$router.push('home?game=4')
                    }
                } catch (e) {

                } finally {

                }
            },
            handleSubmit() {
                if (localStorage.getItem('info')){
                    let info = JSON.parse(localStorage.getItem('info'));
                    let id = info.id
                    this.update({id, g5: this.point})
                } else {
                    this.$router.push('home?game=4')
                }
            },
            goBack() {
                this.$router.push('game5')
            },
            goMenu() {
                this.$router.push('home?game=4')
            },
        },
        mounted() {
            if (this.$route && this.$route.query) {
                this.point = this.$route.query.point
            }
            setTimeout(() => {
                this.loaded = true
            }, 500)
            this.$refs.game5res.play()
        },
        beforeDestroy() {
            this.$refs.game5res.pause()
        },
    }
</script>

<style scoped lang="scss">
    .page-container{
        width: 100vw;
        height: 100vh;
        background-image: url("../../assets/imgs/g5-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        .form{
            width: 495px;
            height: 890px;
            background-size: cover;
            background-repeat: no-repeat;
            @include center();
            .form-box{
                width: 494px;
                height: 495px;
            }
            .star{
                width: 290px;
                height: 114px;
                @include horizontal();
                top: 180px;
                z-index: 15;

            }
            .point{
                width: 100%;
                height: 60px;
                text-align: center;
                line-height: 60px;
                color: #333333;
                position: absolute;
                @include horizontal();
                top: 345px;
                z-index: 10;
                text-align: center;
                span{
                    font-size: 50px;
                    font-weight: bold;
                    font-family: DIN;
                    position: relative;
                    display: inline-block;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .submit{
                width: 483px;
                height: 82px;
                @include horizontal();
                bottom: 254px;
                z-index: 15;
                cursor: pointer;
            }
            .again{
                width: 483px;
                height: 82px;
                @include horizontal();
                bottom: 126px;
                z-index: 15;
                cursor: pointer;
            }
            .back{
                width: 483px;
                height: 82px;
                @include horizontal();
                bottom: 0px;
                z-index: 15;
                cursor: pointer;
            }
        }

    }
    .loaded{
        .form{

        }

    }
</style>
