<template>
  <div id="app">
    <img style="display: none" src="./assets/imgs/short.jpg" alt="">
    <router-view></router-view>
<!--    <audio-->
<!--      id="audio"-->
<!--      ref="playerIndex"-->
<!--      loop="loop"-->
<!--      preload="auto"-->
<!--      controls-->
<!--    >-->
<!--      <source src="./assets/home.mp3">-->
<!--    </audio>-->
  </div>
</template>
<script>
  import {mapState, mapActions} from 'vuex';
  // import wx from 'weixin-js-sdk';
  import * as api from '@/api';
  const shareImg = require('./assets/imgs/short.jpg');
  export default {
  name: 'App',
  // computed: {
  //   ...mapState({ play: state => state.demo.play}),
  // },
  // watch: {
  //   play(val) {
  //     if (val) {
  //       this.$refs.playerIndex.play()
  //       setTimeout(() => {
  //         if (this.$refs.playerIndex.paused){
  //           this.playAudio(false);
  //         }
  //       }, 100)
  //     } else {
  //       this.$refs.playerIndex.pause()
  //     }
  //   }
  // },
  // methods: {
  //   ...mapActions({ playAudio: 'demo/playAudio', }),
  // },
  data() {
    return{
    }
  },
  methods: {
    async getList (){
      try {
        const {data} = await api.getParams({url: location.href})
        if (data.code) {
          wx.config({
            debug: true,
            appId: data.data.appId,
            timestamp: data.data.timestamp,
            nonceStr: data.data.nonceStr,
            signature: data.data.signature,
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
          });
          wx.ready(function () {
            wx.updateAppMessageShareData({
              title: '云间元宇宙网络安全守护之旅',
              desc: '2023年国家网络安全宣传周  上海地区网络安全嘉年华',
              link: 'https://gameweb.chenglankj.cn',
              imgUrl: 'https://gameweb.chenglankj.cn/static/short.jpg', // 分享图标
              success: function () {
              }, fail: function () {
              }
            })
            wx.updateTimelineShareData({
              title: '个人测试云间元宇宙网络安全守护之旅',
              link: 'https://gameweb.chenglankj.cn',
              imgUrl: 'https://gameweb.chenglankj.cn/static/short.jpg', // 分享图标
              success: function () {
              }, fail: function () {
              }
            })
          });
        }
      } catch (e) {

      } finally {

      }
    },
  },
  mounted() {
    // this.getList();
  }
}
</script>

<style lang="scss">
  #audio{
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }
  audio{
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }
  @import "assets/css/normalize.css";
  @font-face {
    font-family: DIN;
    src: url("./assets/fonts/DIN.otf");
  }
  @font-face {
    font-family: DIGITAL;
    src: url("./assets/fonts/DIGITAL.ttf");
  }
  .main-mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    .mask-remain{
      width: 100%;
      text-align: center;
      height: 300px;
      line-height: 300px;
      font-size: 240px;
      font-weight: bold;
      font-family: DIN;
      color: #fff;
      @include center();
    }
  }
</style>
