import Vue from "vue";
import VueRouter from "vue-router";
import Demo from "@/pages/demo";
import Home from "@/pages/home";
import Game1 from "@/pages/game1";
import Game1Res from "@/pages/game1/res.vue";

import Game2 from "@/pages/game2";
import Game2Res from "@/pages/game2/res.vue";

import Game3 from "@/pages/game3";
import Game3Res from "@/pages/game3/res.vue";

import Game4 from "@/pages/game4";
import Game4Res from "@/pages/game4/res.vue";

import Game5 from "@/pages/game5";
import Game5Res from "@/pages/game5/res.vue";

import Game6 from "@/pages/game6";

import Complete from "@/pages/complete";

import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "home",
        component: Layout,
        children: [
            {
                path: "/home",
                name: "home",
                component: Home
            },
            {
                path: "/game1",
                name: "game1",
                component: Game1
            },
            {
                path: "/game1Res",
                name: "game1Res",
                component: Game1Res
            },
            {
                path: "/game2",
                name: "game2",
                component: Game2
            },
            {
                path: "/game2Res",
                name: "game2Res",
                component: Game2Res
            },
            {
                path: "/game3",
                name: "game3",
                component: Game3
            },
            {
                path: "/game3Res",
                name: "game3Res",
                component: Game3Res
            },
            {
                path: "/game4",
                name: "game4",
                component: Game4
            },
            {
                path: "/game4Res",
                name: "game4Res",
                component: Game4Res
            },
            {
                path: "/game5",
                name: "game5",
                component: Game5
            },
            {
                path: "/game5Res",
                name: "game5Res",
                component: Game5Res
            },
            {
                path: "/game6",
                name: "game6",
                component: Game6
            },
            {
                path: "/complete",
                name: "complete",
                component: Complete
            },

        ]
    }
];

export default new VueRouter({
    routes,
    mode: 'history',
});
