<template>
   <div class="page-container">
      <audio
        ref="game1"
        loop="loop"
        preload="auto"
        controls
      >
         <source src="../../assets/game1.mp3">
      </audio>
      <div class="cover" v-show="showCover">
         <img class="bg" src="../../assets/imgs/game1.jpg" alt="">
         <img class="start" @click="hideCover" src="../../assets/imgs/game1-start.png" alt="">
      </div>
      <div class="main-mask"  v-show="!showCover" v-if="startRemain > 0">
         <div class="mask-remain">
            {{startRemain}}
         </div>
      </div>
      <div class="main-container" v-show="!showCover">
         <div class="top">
            <div class="point">得分：<span>{{point}}</span></div>
            <img src="../../assets/imgs/g1-top.jpg" alt="">
            <div class="progress">
               <div class="circle-container">
                  <van-circle
                          v-model="currentRate"
                          :rate="rate"
                          layer-color="#d4d6d8"
                          :speed="100 / 60"
                          :stroke-width="80"
                          :color="gradientColor"
                          :text="remain + 's'"
                          :size="pxToVw(110)"
                          :clockwise="false"
                  />
               </div>

            </div>
         </div>
         <div class="bottom">
            <img class="bg" src="../../assets/imgs/g1-ground.png" alt="">
            <img src="../../assets/imgs/g1-people.png" alt="" class="grab-people">
            <div class="bonus" :class="showBonus ? 'bonus-on' : ''">{{calc > 0 ? '+': ''}}{{calc}}</div>
            <div class="machine" @click="handleGrab">
               <div class="pointer" :class="startRemain <= 0 ? 'pointer-ani' : ''">
                  <img src="../../assets/imgs/finger.png" alt="">
               </div>
               <img src="../../assets/imgs/machine.png" alt="" class="grab-machine">
               <div class="origin" ref="origin"></div>
               <div class="line" :class="grabbing ? 'grab': 'rotate'" :style="{height: pxToVw(length) + 'px'}">
                  <div class="hook" :class="grabbed ? 'grabbed' : ''" >
                     <img class="hook1" src="../../assets/imgs/hook1.png" alt="">
                     <img class="hook2" src="../../assets/imgs/hook2.png" alt="">
                  </div>
                  <div class="true-hook" ref="hook"></div>
               </div>
            </div>
            <div class="container">
               <img v-for="item in list" :id="'img' + item.id" ref="img" :code="item.id" :key="item.id" :style="{
                    top: item.top + 'px',
                    left: item.left + 'px',
                    width: pxToVw(item.width) + 'px',
                    height: pxToVw(item.height) + 'px',
                    transform: code == item.id ? transform: '',
                    transition: code == item.id ? transition: '',
                 }" :src="item.img" alt="">
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import {pxToVw} from '@/utils/index.js'
   export default {
       name: "index",
       data() {
           return {
              startRemain: 3,
              showCover: true,
              grabbing: false,
              grabbed: false,
              length: 200,
              timer: null,
              recoverTimer: null,
              remainTimer: null,
              startTimer: null,
              transform: '',
              transition: '',
              code: 0,
              list: [],
              rate: 100,
              currentRate: 100,
              remain: 60,
              gradientColor: {'0%': '#3fecff', '100%': '#561fe0',},
              point: 0,
              calc: 0,
              showBonus: false,

           }
       },
       methods: {
          pxToVw,
          hideCover() {
             this.showCover = false;
             this.handleStartRemain();
             setTimeout(() => {
                this.$refs.game1.play()
             }, 100)
          },
          handleGrab() {
             if (this.grabbing) {
                return;
             }
             this.grabbing = true;
             this.timer = setInterval(() => {
                if (this.checkGrab() || this.length >= 1000) {
                   clearInterval(this.timer);
                   this.timer = null;
                   this.recoverGrab();
                } else {
                   this.length = this.length + 5
                }
             }, 16)
          },
          recoverGrab() {
             this.recoverTimer = setInterval(() => {
                if (this.length <= 200) {
                   clearInterval(this.recoverTimer);
                   this.recoverTimer = null;
                   this.grabbing = false
                } else {
                   this.length = this.length - 5
                }
             }, 16)
          },
          getTop(item) {
             let top = Math.random() * (window.innerHeight - this.pxToVw(820) - this.pxToVw(item.height));
             return top;
          },
          getLeft(item) {
             let left = Math.random() * (window.innerWidth);
             if (left - item.width < 0) {
                left = item.width
             }
             if (left + item.width > window.innerWidth) {
                left = window.innerWidth - item.width
             }
             return left;
          },
          checkGrab() {
             let hook = this.$refs.hook;
             const hookRect = hook.getBoundingClientRect();
             let imgs = this.$refs.img;
             let code = 0;
             imgs.forEach(el => {
               const elRect = el.getBoundingClientRect();
               const overlap =
                     hookRect.left < elRect.right &&
                     hookRect.right > elRect.left &&
                     hookRect.top < elRect.bottom &&
                     hookRect.bottom > elRect.top;
               if (overlap) {
                  code = el.getAttribute('code')
               }
             })
             if (code) {
                let origin = this.$refs.origin;
                const originRect = origin.getBoundingClientRect();
                let el = document.getElementById('img' + code);
                const elRect = el.getBoundingClientRect();
                this.code = code;
                let target = {}
                this.list.forEach(item => {
                   if (item.id == code) {
                      target = item;
                   }
                })
                let time = ((this.length + 60)/ 5) * 16 / 1000
                this.transform = `translateX(${originRect.left - elRect.left - this.pxToVw(target.width / 2)}px) translateY(${originRect.top - elRect.top - this.pxToVw(target.height / 2)}px)`
                this.transition = `${time}s linear`
                this.grabbed = true
                setTimeout(() => {
                   let arr = []
                   this.list.forEach(item => {
                      if (item.id != code) {
                         arr.push(item)
                      }
                   })
                   this.list = arr;
                   this.grabbed = false;
                   this.point = this.point + target.point
                   this.showBonus = true;
                   this.calc = target.point
                   setTimeout(() => {
                      this.showBonus = false
                      this.calc= 0;
                      if (this.list.length === 0 || this.list.every(item => item.point < 0)) {
                         this.finishGame();
                      }
                   }, 1200)
                }, time * 1000 - 40 * 16)
                return true;
             } else {
                return false
             }
          },
          getMap2() {
             let list = [
                {id: 1, img: require('../../assets/imgs/g1-icon1.png'), width: 104, height: 103, type: 1, point: -5,},
                {id: 2, img: require('../../assets/imgs/g1-icon2.png'), width: 78, height: 77, type: 1, point: -5,},
                {id: 3, img: require('../../assets/imgs/g1-icon3.png'), width: 88, height: 103, type: 2, point: -5,},
                {id: 4, img: require('../../assets/imgs/g1-icon5.png'), width: 92, height: 113, type: 3, point: 10,},
                {id: 5, img: require('../../assets/imgs/g1-icon4.png'), width: 151, height: 89, type: 4, point: 10,},
             ]
             let plan1 = {
                1: {left: 380, top: 100},
                2: {left: 30, top: 135},
                3: {left: 380, top: 300},
                4: {left: 108, top: 360},
                5: {left: 550, top: 529},
             }
             let plan2 = {
                1: {left: 134, top: 300},
                2: {left: 590, top: 120},
                3: {left: 300, top: 220},
                4: {left: 200, top: 30},
                5: {left: 500, top: 560},
             }
             let plan3 = {
                1: {left: 120, top: 480},
                2: {left: 300, top: 120},
                3: {left: 600, top: 250},
                4: {left: 50, top: 300},
                5: {left: 450, top: 350},
             }
             let idx = Math.ceil(Math.random()*3)
             let obj = {}
             if (idx == 1) {
                obj = plan1
             } else if (idx == 2) {
                obj = plan2
             } else if (idx == 3) {
                obj = plan3
             }
             list.forEach((item, index) => {
                list[index].left = this.pxToVw(obj[item.id].left)
                list[index].top = this.pxToVw(obj[item.id].top)
             })
             this.list = list;
          },
          getMap() {
             let scale = 0.6
             let list = [
                {id: 1, img: require('../../assets/imgs/g1-icon5.png'), width: 92 * scale, height: 113* scale, type: 3, point: 10,},
                {id: 2, img: require('../../assets/imgs/g1-icon2.png'), width: 78* scale, height: 77* scale, type: 1, point: -5,},
                {id: 3, img: require('../../assets/imgs/g1-icon5.png'), width: 92* scale, height: 113* scale, type: 3, point: 10,},
                {id: 4, img: require('../../assets/imgs/g1-icon1.png'), width: 104* scale, height: 103* scale, type: 1, point: -5,},
                {id: 5, img: require('../../assets/imgs/g1-icon4.png'), width: 151* scale, height: 89* scale, type: 4, point: 10,},
                {id: 6, img: require('../../assets/imgs/g1-icon3.png'), width: 88* scale, height: 103* scale, type: 2, point: -5,},
                {id: 7, img: require('../../assets/imgs/g1-icon4.png'), width: 151* scale, height: 89* scale, type: 4, point: 10,},
             ]
             let maxLeft = window.innerWidth - this.pxToVw(160);
             let maxTop = window.innerHeight - this.pxToVw(660 + 200 + 150);
             let num = 20 - Math.round(Math.random() * 5);
             let intervelLeft = maxLeft / 6.5;
             let intervelTop = maxTop / 6.5;
             let res = {}
             let plan = [];
             const genFunc = (arr) => {
                let left = maxLeft - Math.round(Math.random() * maxLeft);
                let top = maxTop - Math.round(Math.random() * maxTop);
                let err = 0;
                arr.forEach(item => {
                   if(Math.abs(item.left -  left) <= intervelLeft && Math.abs(item.top -  top) <= intervelTop) {
                      err = err + 1;
                   }
                })
                if (err) {
                   genFunc(arr);
                } else {
                   res =  {left, top}
                }
             }
             for(let i = 0;i < num; i ++) {
                genFunc(plan)
                plan.push(res)
             }
             let finList = []
             plan.forEach((item, index) => {
                let idx = index % 7;
                finList.push({
                   ...list[idx],
                   ...item,
                   id: index
                })
             })

             this.list = finList;
          },
          finishGame() {
             this.$router.push({
                name: 'game1Res',
                query: {point: this.point}
             })
          },
          handleStartRemain() {
             if (this.startTimer) {
                clearInterval(this.startTimer)
                this.startTimer = null;
             }
             this.startTimer = setInterval(() => {
                if (this.startRemain <= 1 ) {
                   this.startRemain = this.startRemain  - 1;
                   clearInterval(this.startTimer)
                   this.startTimer = null;
                   this.startGame();
                } else {
                   this.startRemain = this.startRemain  - 1;
                }
             }, 1000)
          },
          startGame() {
             if (this.remainTimer) {
                clearInterval(this.remainTimer)
                this.remainTimer = null;
             }
             setTimeout(() => {
                this.rate = 0;
             }, 1000)
             this.remainTimer = setInterval(() => {
                if (this.remain <=0 ) {
                   clearInterval(this.remainTimer)
                   this.remainTimer = null;
                   this.finishGame();
                } else {
                   this.remain = this.remain  - 1;
                }
             }, 1000)
          }
       },
       created() {
          this.getMap()
       },
       mounted() {
          this.$refs.game1.play()
       },
       beforeDestroy() {
          clearInterval(this.remainTimer)
          this.remainTimer = null;
          clearInterval(this.recoverTimer);
          this.recoverTimer = null;
          clearInterval(this.timer);
          this.timer = null;
          clearInterval(this.startTimer);
          this.startTimer = null;
          this.$refs.game1.pause()
       },
    }
</script>

<style scoped lang="scss">

   .page-container{
      width: 100vw;
      min-height: 100vh;
      .cover{
         width: 100vw;
         min-height: 100vh;
         background: #fcfdff;
         position: relative;
      }
      .bg{
         width: 100%;
      }
      .start{
         @include horizontal();
         top: 1240px;
         width: 369px;
         height: 142px;
      }
      .main-container{
         width: 100vw;
         height: 100vh;
         position: relative;
         background: #9c6b3f;
         .top{
            width: 100%;
            height: 660px;
            position: relative;
            img{
               width: 100%;
            }
            .point{
               position: absolute;
               width: 200px;
               left: 60px;
               top: 60px;
               z-index: 10;
               font-size: 30px;
               color: #fff;
               span{
                  font-size: 36px;
                  font-weight: bold;
                  font-family: DIN;
               }
            }
            .progress{
               position: absolute;
               width: 127px;
               height: 127px;
               right: 60px;
               top: 60px;
               z-index: 10;
               background: #fff;
               border-radius: 50%;
               .circle-bg{
                  width: 106px;
                  height: 106px;
                  @include center();
                  border: 4px solid #d4d6d8;
                  border-radius: 50%;
                  box-sizing: border-box;
                  z-index: 5;
               }
               .circle-container{
                  width: 110px;
                  height: 110px;
                  @include center();
                  z-index: 10;
                  ::v-deep(.van-circle__text){
                     color: #4377ff;
                     font-size: 36px;
                     font-family: DIN;
                     font-weight: bold;
                  }
               }
            }
         }
         .bottom{
            width: 100%;
            position: absolute;
            top: 620px;
            height: calc(100vh - 620px);
            left: 0;
            z-index: 10;
            .bonus{
               width: 100px;
               text-align: center;
               font-size: 60px;
               font-weight: bold;
               color: #fff;
               font-family: DIN;
               @include horizontal();
               z-index: 15;
               top: -50px;
               text-shadow: 0 0 10px rgba(0,0,0,0.3);
               opacity: 0;
            }
            .bonus-on{
               animation: bonus 1.2s ease forwards;
            }
            @keyframes bonus {
               0%{
                  transform: translateY(0px);
                  opacity: 1;
               }
               100%{
                  transform: translateY(-100px);
                  opacity: 0;
               }
            }
            .grab-people{
               width: 209px;
               height: 313px;
               @include horizontal();
               top: -300px;
               z-index: 5;
               transform: translateX(150px);
            }
            .machine{
               width: 242px;
               height: 175px;
               @include horizontal();
               top: -160px;
               z-index: 10;
               .pointer{
                  position: absolute;
                  top: 0px;
                  left: 60px;
                  width: 64px;
                  height: 64px;
                  z-index: 10;
                  pointer-events: none;
               }
               .pointer-ani{
                  animation: point 1s ease-out infinite;
                  @keyframes point {
                     0% {
                        transform: scale(1);
                        opacity: 1;
                     }
                     50% {
                        transform: scale(1);
                        opacity: 1;
                     }
                     75% {
                        transform: scale(0.85);
                        opacity: 1;
                     }
                     99% {
                        opacity: 1;
                     }
                     100% {
                        transform: scale(1);
                        opacity: 0;
                     }
                  }
               }
               .grab-machine{
                  width: 242px;
                  height: 175px;
               }
               .origin{
                  width: 1px;
                  height: 1px;
                  @include horizontal();
                  top: 78px;
               }
               .line{
                  width: 3px;
                  background: #333;
                  @include horizontal();
                  top: 78px;
                  transform-origin: top;
                  transform: rotate(45deg);
                  .hook{
                     width: 114px;
                     height: 66px;
                     display: flex;
                     justify-content: space-between;
                     position: absolute;
                     left: -57px;
                     bottom: -60px;
                     img{
                        width: 57px;
                        height: 66px;
                        transform-origin: top;
                        transition: 0.1s ease;
                     }
                  }
                  .grabbed{
                     .hook1{
                        transform: rotate(-20deg);
                     }
                     .hook2{
                        transform: rotate(20deg);
                     }
                  }
                  .true-hook{
                     width: 50px;
                     height: 10px;
                     position: absolute;
                     left: -25px;
                     bottom: -10px;
                  }
                  animation: rotate 5s ease-in-out infinite forwards;
               }
               .rotate{
                  animation-play-state: running;
               }
               .grab{
                  animation-play-state: paused;
               }
               @keyframes rotate {
                  0% {
                     transform: rotate(45deg);
                  }
                  50% {
                     transform: rotate(-45deg);
                  }
                  100% {
                     transform: rotate(45deg);
                  }
               }
            }
            .bg{
               width: 100%;
            }
            .container{
               width: 100%;
               height: calc(100% - 200px);
               position: absolute;
               top: 200px;
               left: 0;
               z-index: 50;
               img{
                  position: absolute;
                  z-index: 10;
               }
            }
         }
      }
   }
</style>
