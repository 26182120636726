<template>
    <div class="page-container" :class="loaded ? 'loaded' : ''">
        <audio
          ref="complete"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="@/assets/home.mp3">
        </audio>
        <div class="form">
            <img src="../../assets/imgs/complete-title.png" class="title" alt="">
<!--            <img src="../../assets/imgs/complete-1.png" alt="" class="complete-1">-->
            <img src="../../assets/imgs/complete-2.png" alt="" class="complete-2">
            <img src="../../assets/imgs/complete-3.png" alt="" class="complete-3">
            <img src="../../assets/imgs/complete-4.png" alt="" class="complete-4">
            <img src="../../assets/imgs/complete-5.png" alt="" class="complete-5">
            <img src="../../assets/imgs/complete-6.png" alt="" class="complete-6">
            <img src="../../assets/imgs/complete-7.png" alt="" class="complete-7">
            <img src="../../assets/imgs/logo.png" class="logo" alt="">
            <div class="des">
                <img src="../../assets/imgs/complete-text.png" alt="">
            </div>
            <div class="des" v-if="false">
                前<span>200</span>名将有机会获得2023年国家网络安全宣传周上海地区网络安全嘉年华主会场“神秘星球”大挑战邀请函，请注意查看您的邮箱，我们在线下期待您的到来！
            </div>
        </div>
    </div>
</template>

<script>
    import * as api from '@/api';
    export default {
        name: "res",
        data() {
           return {
               loaded: false,
               point: 0
           }
        },
        methods: {
            goBack() {
                this.$router.push('game2')
            },
            goMenu() {
                this.$router.push('home')
            }
        },
        mounted() {
            if (this.$route && this.$route.query) {
                this.point = this.$route.query.point
            }
            setTimeout(() => {
                this.loaded = true
            }, 500)
            this.$refs.complete.play()
        }
    }
</script>

<style scoped lang="scss">
    .page-container{
        width: 100vw;
        height: 100vh;
        background-image: url("../../assets/imgs/complete-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        .form{
            width: 572px;
            height: 684px;
            background-image: url("../../assets/imgs/complete-box.png");
            background-size: cover;
            background-repeat: no-repeat;
            @include center();
            transition: 1s ease;
            opacity: 0;
            transform: translateY(100px);
        }
        .title{
            width: 414px;
            height: 164px;
            position: absolute;
            @include horizontal();
            top: 70px;
            z-index: 100;
        }
        .complete-1{
            width: 161px;
            height: 239px;
            position: absolute;
            bottom: -177px;
            left: -100px;
            z-index: 1;
            transition: 1s ease;
            transition-delay: 0.5s;
            opacity: 0;
            transform: translateY(-30px);
        }
        .complete-2{
            width: 229px;
            height: 340px;
            position: absolute;
            top: -80px;
            left: -92px;
            z-index: 50;
            transition: 1s ease;
            transition-delay: 0.5s;
            opacity: 0;
            transform: translateX(-30px);
        }
        .complete-3{
            width: 148px;
            height: 105px;
            position: absolute;
            top: -172px;
            right: -12px;
            z-index: 50;
            transition: 1s ease;
            transition-delay: 0.6s;
            opacity: 0;
            transform: translateY(-30px);
        }
        .complete-4{
            width: 166px;
            height: 118px;
            position: absolute;
            top: 108px;
            right: -78px;
            z-index: 50;
            transition: 1s ease;
            transition-delay: 0.7s;
            opacity: 0;
            transform: translateX(40px);
        }
        .complete-5{
            width: 189px;
            height: 190px;
            position: absolute;
            bottom: -126px;
            right: -78px;
            z-index: 50;
            transition: 1s ease;
            transition-delay: 0.8s;
            opacity: 0;
            transform: translateX(50px);
        }
        .complete-6{
            width: 228px;
            height: 165px;
            position: absolute;
            bottom: -107px;
            left: -100px;
            z-index: 1;
            transition: 1s ease;
            transition-delay: 0.5s;
            opacity: 0;
            transform: translateY(-30px);
        }
        .complete-7{
            width: 235px;
            height: 180px;
            position: absolute;
            bottom: -186px;
            right: 158px;
            z-index: 50;
            transition: 1s ease;
            transition-delay: 0.8s;
            opacity: 0;
            transform: translateY(30px);
        }
        .des{
            width: 480px;
            height: 330px;
            @include horizontal();
            top: 265px;
            z-index: 101;
            font-size: 30px;
            color: #fff;
            line-height: 50px;
            span{
                font-weight: bold;
            }
            img{
                width: 476px;
                height: 263px;
            }
        }
        .logo{
            width: 214px;
            height: 124px;
            @include horizontal();
            top: -170px;
        }
    }
    .loaded{
        .form{
            transform: translateY(0);
            opacity: 1;
        }
        .complete-1{
            transform: translateY(0);
            opacity: 1;
        }
        .complete-2{
            transform: translateX(0);
            opacity: 1;
        }
        .complete-3{
            transform: translateY(0);
            opacity: 1;
        }
        .complete-4{
            transform: translateX(0);
            opacity: 1;
        }
        .complete-5{
            transform: translateX(0);
            opacity: 1;
        }
        .complete-6{
            transform: translateX(0) translateY(0);
            opacity: 1;
        }
        .complete-7{
            transform: translateY(0);
            opacity: 1;
        }
    }
</style>
