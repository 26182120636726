<template>
    <div class="page-container" ref="post">
        <audio
          ref="game6"
          loop="loop"
          preload="auto"
          controls
        >
            <source src="../../assets/game6.mp3">
        </audio>
        <div class="cover" v-show="showCover">
            <img class="bg" src="../../assets/imgs/game6-cover.jpg" alt="">
            <img class="start" @click="hideCover" src="../../assets/imgs/game6-start.png" alt="">
        </div>
        <div class="share-body" v-if="postSrc">
            <img :src="postSrc" alt="" class="post" @touchstart="onTouchStart($event)"
                 @touchmove="onTouchMove($event)"
                 @touchend="onTouchEnd">
            <div class="tips">
                <img src="../../assets/imgs/g6-finger.png" alt=""><br>
                <span>请长按分享</span>
            </div>
        </div>
<!--        <div class="cover" v-show="showCover">-->
<!--            <img @click="showCover = false" class="bg" src="../../assets/imgs/game6.jpg" alt="">-->
<!--        </div>-->
        <div class="cut" v-if="showCut">
            <vue-cropper ref="cropper" :img="img" :output-size="option.size" :info="true" :full="option.full"
                         :can-move="option.canMove" :can-move-box="option.canMoveBox" :fixed-box="option.fixedBox" :original="option.original"
                         :auto-crop="option.autoCrop" :auto-crop-width="option.autoCropWidth" :auto-crop-height="option.autoCropHeight" :center-box="option.centerBox"
                         :high="option.high"
                         mode="contain" :max-img-size="option.max"></vue-cropper>
            <div class="button" @click="finish">确认</div>
        </div>
        <div class="main-container">
            <div class="main-body">
                <img class="title" src="../../assets/imgs/g6-shape.png" alt="">
                <div class="calendar">
                    <img class="calendar-img" src="../../assets/imgs/g6-c.png" alt="">
                    <div class="c-title">
                        <div class="cur-day">
                            <span>{{today}}</span>
                        </div>
                        <div class="square">
                            <img src="../../assets/imgs/c-square.png" alt="">
                        </div>
                        <div class="e-name">
                            <span :style="{fontSize: months.indexOf(eName) > -1 ? pxToVw(23) + 'px' : pxToVw(31) + 'px' }">{{eName}}</span>
                        </div>
                    </div>
                    <div class="c-week">
                        <div class="item">M</div>
                        <div class="item">T</div>
                        <div class="item">W</div>
                        <div class="item">T</div>
                        <div class="item">F</div>
                        <div class="item pink">S</div>
                        <div class="item pink">S</div>
                    </div>
                    <div class="c-table">
                        <div class="item" :class="[((item + firstDay + daysInMonth) % 7 - 2 < 0) ? 'lightpink': '' ]"  style="color: #C6D5DD;" v-for="item in prev" :key="'p' + item">
                            <span>{{item}}</span>
                        </div>
                        <div class="item" :class="[+today == item ? 'item-on': '', ((item + firstDay) % 7 - 2 < 0) ? 'pink': '' ]" v-for="item in daysInMonth" :key="'k' + item">
                            <span>{{item}}</span>
                        </div>
                        <div class="item" :class="[((item + firstDay + daysInMonth) % 7 - 2 < 0) ? 'lightpink': '' ]" style="color: #C6D5DD;" v-for="item in (42 - daysInMonth  - prev.length) " :key="'a' + item">
                            <span>{{item}}</span>
                        </div>
                    </div>
                    <img src="../../assets/imgs/c-shape.png" alt="" class="c-shape">
                </div>
                <div class="camera-border"></div>
                <div class="camera-body" :style="{background: src ? '#fff': '#e5e5e5'}">
                    <input type="file" @change="fileChange">
                    <img src="../../assets/imgs/g6-camera.png" alt="" class="icon">
                    <div class="preview" :style="{backgroundImage: 'url('+src+')'}" v-if="src" alt=""></div>
                </div>
                <div class="input" @input="inputChange" contenteditable="true">{{value}}</div>
                <div class="btns" v-if="!sharing">
<!--                    <img @click="handleSubmit" class="submit" src="../../assets/imgs/g6-submit.png" alt="">-->
                    <img @click="handleShare" class="share" src="../../assets/imgs/g6-share.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Toast } from 'vant'
    import { VueCropper }  from 'vue-cropper'
    import html2canvas from 'html2canvas'
    import * as api from '@/api';
    import moment from 'moment';
    import {pxToVw} from '@/utils/index.js'
    export default {
        name: "index",
        components: {
            VueCropper
        },
        data() {
            return {
                showCut: false,
                showCover: true,
                value: '点击输入文字内容',
                previews: '',
                src: '',
                img: '',
                sharing: false,
                postSrc: '',
                option: {
                    size: 1,
                    full: false,
                    canMove: true,
                    fixedBox: false,
                    original: false,
                    canMoveBox: true,
                    autoCrop: true,
                    // 只有自动截图开启 宽度高度才生效
                    centerBox: true,
                    high: true,
                    max: 99999
                },
                prev: [],
                today: '',
                firstDay: 0,
                daysInMonth: 30,
                eName: '',
                months: ['February', 'September', 'November', 'December'],
                toucheX: 0,
                toucheY: 0,
                timeOutEvent: null
            }
        },
        methods: {
            pxToVw,
            inputChange(event) {
                const diff = event.target.innerText.length - 40;
                if (diff > 0) {
                    const range = document.createRange();
                    const sel = window.getSelection();
                    const offset = sel.anchorOffset;
                    const node = sel.anchorNode;
                    const text = node.textContent;
                    range.selectNodeContents(node);
                    sel.removeAllRanges();
                    sel.addRange(range);
                    sel.extend(node, offset);
                    document.execCommand('delete', false);
                    document.execCommand(
                      'insertText',
                      false,
                      text.substring(0, offset - diff)
                    );
                }
            },

            hideCover() {
                this.showCover = false;
                setTimeout(() => {
                    this.$refs.game6.play()
                }, 100)
            },
            async update (form, post){
                try {
                    const {data} = await api.update(form)
                    if (data.code) {
                        let result = data.data;
                        if (!post) {
                            if (result && result.g1 && result.g2 && result.g3 && result.g4 && result.g5 && result.g6) {
                                this.$router.push('complete')
                            } else {
                                this.$router.push('home?game=5')
                            }
                        }
                    } else {
                        this.$router.push('home?game=5')
                    }
                } catch (e) {

                } finally {

                }
            },
            async upFile (base64){
                try {
                    const {data} = await api.upFile({base64: base64, type: 'jpg'})
                    if (data.code) {
                        // this.postSrc = 'https://gamephp.chenglankj.cn/storage/upload/698427a81e6eae80a2743955d296fc57.jpg'
                        this.postSrc = 'https://gamephp.chenglankj.cn/storage/upload/' + data.data.url
                        this.sharing = false;
                    } else {
                        this.$router.push('home?game=5')
                    }
                } catch (e) {

                } finally {
                    Toast('请长按分享')

                }
            },
            handleSubmit() {
                if (localStorage.getItem('info')){
                    let info = JSON.parse(localStorage.getItem('info'));
                    let id = info.id
                    this.update({id, g6: 1})
                } else {
                    this.$router.push('home?game=5')
                }
            },
            fileChange(e) {
                let that = this;
                let img=e.target.files[0]; //获取到上传文件的对象
                var reader = new FileReader();
                reader.readAsDataURL(img);//参数为上传的文件对象 传值进去就会触发以下onload方法
                reader.onload = function (e) {
                    const base64Img = e.target.result
                    that.img = base64Img;
                    that.showCut = true;
                }
            },
            finish() {
                this.$refs.cropper.getCropData((data) => {
                    this.src = data
                    this.showCut =false
                })
            },
            isIos() {
                const u = navigator.userAgent
                this.u = u;
                return u.includes('iPhone') || u.includes('Mac OS')
            },
            handleShare() {
                if (localStorage.getItem('info')){
                    let info = JSON.parse(localStorage.getItem('info'));
                    let id = info.id
                    this.update({id, g6: 1}, true)
                }
                this.sharing = true;
                Toast('生成中')
                setTimeout(() => {
                    this.htmlToCanvas()
                }, 0)
            },
            htmlToCanvas() {
                html2canvas(this.$refs.post, {})
                    .then((canvas) => {
                        let imageUrl = canvas.toDataURL('image/jpeg'); // 将canvas转成base64图片格式
                        // this.upFile(imageUrl)
                        this.postSrc = imageUrl;
                        this.sharing = false;
                        Toast('请长按分享')
                    });
            },
            // 长按弹出上拉操作面板
            onTouchStart (e) {
                this.toucheX = e.targetTouches[0].screenX
                this.toucheY = e.targetTouches[0].screenY
                // 开启定时器前先清除定时器，防止重复触发
                this.timeOutEvent && clearTimeout(this.timeOutEvent)
                // 显示上拉面板
                if (this.isIos) {
                    this.timeOutEvent = setTimeout(() => {
                        this.handleSubmit();
                    }, 1000)
                } else {
                    this.timeOutEvent = setTimeout(() => {
                        this.handleSubmit();
                    }, 1000)
                }
                // e.preventDefault() // 阻止系统默认事件
            },
            onTouchMove (e) {
                const moveX = e.targetTouches[0].screenX
                const moveY = e.targetTouches[0].screenY
                // 解决vivo机型，手指没有move，touchmove事件仍然会调用而导致setTimeout被clear
                if (this.toucheX !== moveX || this.toucheY !== moveY) {
                    // 手指滑动，清除定时器，中断长按逻辑
                    this.timeOutEvent && clearTimeout(this.timeOutEvent)
                }
            },
            onTouchEnd () {
                // 清除定时器，结束长按逻辑
                this.timeOutEvent && clearTimeout(this.timeOutEvent)
                // 若手指离开屏幕，时间小于我们设置的长按时间，则为点击事件
            },
        },
        mounted() {
            const firstDayOfMonth = moment().startOf('month');
            // 获取星期几（0 表示星期日，1 表示星期一，以此类推）
            const dayOfWeek = firstDayOfMonth.day();
            this.firstDay = dayOfWeek ? dayOfWeek: 7;
            let lastDays = 0

            if (dayOfWeek == 0) {
                lastDays = 6
            } else {
                lastDays = dayOfWeek -1
            }

            // 获取上个月的最后一天
            const lastDayOfLastMonth = moment().subtract(1, 'months').endOf('month');

            // 获取上个月的最后 7 天
            const last7Days = [];
            for (let i = 0; i < lastDays; i++) {
                const day = moment(lastDayOfLastMonth).subtract(i, 'days');
                last7Days.push(day.format('DD'));
            }
            this.today = moment().format('DD')
            this.prev = last7Days.reverse();
            this.daysInMonth =  moment().daysInMonth();
            this.eName = moment().format('MMMM');
            this.$refs.game6.play()
        },
        beforeDestroy() {
            this.$refs.game6.pause()
        },
    }
</script>

<style scoped lang="scss">
    .page-container {
        width: 100vw;
        min-height: 100vh;
        .cover{
            width: 100vw;
            height: 100vh;
            background: #00010b;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
        }
        .bg{
            width: 100%;
            @include fit();
        }
        .start{
            @include horizontal();
            bottom: 80px;
            width: calc(750px / 2);
            height: calc(309px / 2);

        }
        .share-body{
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.3);
            z-index: 1000;
            top: 0;
            left: 0;
            .tips{
                pointer-events: none;
                position: absolute;
                bottom: 10vh;
                width: 100%;
                text-align: center;
                font-size: 28px;
                color: #333;
                z-index: 1001;
                img{
                    width: 64px;
                    height: 64px;
                }
            }
            .post{
                position: absolute;
                width: calc(100% - 100px);
                margin-left: 50px;
                height: 100%;
                object-fit: contain;
                object-position: center;
                z-index: 1000;
            }
        }
        .cut{
            width: 100vw;
            height: 100vh;
            @include center();
            z-index: 999;
            .button{
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 999;
                width: 200px;
                height: 60px;
                background: orange;
                text-align: center;
                color: #fff;
                border-radius: 8px;
                line-height: 60px;
                font-size: 32px;
            }
        }

        .main-container{
            width: 100vw;
            height: 100vh;
            background: #fff;
            position: relative;
            background: url("../../assets/imgs/g6-bg.png") no-repeat center;
            background-size: cover;
            .main-body{
                position: relative;
                width: 100%;
                height: 978px;
                @include center();
            }
            .title{
                position: absolute;
                top: 25px;
                left: 60px;
                z-index: 5;
                width: 307px;
                height: 280px;
            }
            .calendar{
                position: absolute;
                top: 0px;
                right: 60px;
                z-index: 5;
                width: 300px;
                height: 373px;
                .c-shape{
                    position: absolute;
                    width: 298px;
                    height: 10px;
                    bottom: 0;
                    left: 1px;
                    z-index: 10;
                }
                .calendar-img{
                    width: 300px;
                    height: 373px;
                    display: none;
                }
                .c-title{
                    width: 100%;
                    height: 80px;
                    font-size: 18px;
                    overflow: hidden;
                    .cur-day{
                        width: 110px;
                        height: 100%;
                        font-size: 90px;
                        color: #002e72;
                        font-weight: bold;
                        float: left;
                        span{
                            display: inline-block;
                            position: relative;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .square{
                        float: left;
                        width: 56px;
                        height: 63px;
                        margin-top: 5px;
                        img{
                            width: 56px;
                            height: 63px;
                        }
                    }
                    .e-name{
                        margin-top: 5px;
                        width: calc(100% - 56px - 111px - 15px);
                        height: 63px;
                        float: right;
                        color: #002e72;
                        position: relative;
                        text-align: right;
                        white-space: nowrap;
                        span{
                            width: 100%;
                            left: 0;
                            position: absolute;
                            bottom: 0;
                        }
                    }

                }
                .pink{
                    color: #FF6A8C!important;
                }
                .lightpink{
                    color: #FFC2D2!important;
                }
                .c-week{
                    width: 100%;
                    height: 40px;
                    background: #ffc200;
                    box-sizing: border-box;
                    padding: 0 10px;
                    line-height: 40px;
                    @include clear;
                    .item{
                        width: 40px;
                        height: 40px;
                        float: left;
                        text-align: center;
                        font-size: 16px;
                    }
                }
                .c-table{
                    width: 300px;
                    height: 230px;
                    position: absolute;
                    top: 120px;
                    left: 0;
                    box-sizing: border-box;
                    padding: 8px 10px;
                    overflow: hidden;
                    .item{
                        width: 40px;
                        height: 36px;
                        float: left;
                        color: #193948;
                        font-size: 14px;
                        text-align: center;
                        line-height: 36px;
                        position: relative;
                        span{
                            position: relative;
                            z-index: 10;
                        }
                        &:before{
                            content: '';
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            background: #193948;
                            opacity: 0;
                            @include center();
                        }
                    }
                    .item-on{
                        &:before{
                            opacity: 1;
                        }
                        span{
                            color: #fff;
                            font-weight: bold;
                        }
                    }

                }
            }
            .camera-border{
                width: 342px;
                height: 401px;
                border: 2px solid #94e0ff;
                box-sizing: border-box;
                position: absolute;
                left: 111px;
                top: 413px;
                z-index: 5;
            }
            .camera-body{
                width: 353px;
                height: 337px;
                background: #e5e5e5;
                position: absolute;
                z-index: 10;
                left: 70px;
                top: 443px;
                .preview{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 20;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                input{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 25;
                    opacity: 0;
                }
                .icon{
                    display: block;
                    @include center();
                    width: 122px;
                    height: 93px;
                    z-index: 15;
                }
            }
            .input{
                /*width: 206px;*/
                height: 330px;
                border-left: 2px solid #646464;
                border-right: 2px solid #646464;
                position: absolute;
                top: 457px;
                right: calc(136px / 2);
                z-index: 10;
                writing-mode: vertical-lr;
                writing-mode: tb-lr;
                line-height: 43px;
                font-size: 28px;
                letter-spacing: 5px;
                text-align: center;
                //@include elli();
                color: #383838;
                overflow: hidden;
                box-sizing: border-box;
                padding: 0 20px;
            }
            .btns{
                width: 100%;
                text-align: center;
                position: absolute;
                top: 904px;
                letter-spacing: 0;
                z-index: 10;
                height: 77px;
                img{
                    width: 234px;
                    height: 77px;
                    display: inline-block;
                    cursor: pointer;
                }
                .submit{
                    margin-right: 34px;
                }
            }
        }
        .bg {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
            display: block;
        }
    }
</style>
